import OnboardingContainer from '../templates/onboarding'
import { useContext } from 'react'
import { SecurityContext } from '../../context/security-context'
import { GoogleSignIn } from '../atoms/google-sign-in-button'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useBoolean } from '../../hooks/useBoolean'

const LoginPage = () => {
  useDocumentTitle('Myrna - Login')
  const [loading, toggleLoading] = useBoolean(false)
  const { login } = useContext(SecurityContext)

  return (
    <OnboardingContainer title="Welcome to Myrna" image="myrna.webp">
      <div style={{ width: '70%' }}>
        <GoogleSignIn
          loading={loading}
          onClick={() => {
            toggleLoading()
            login().finally(() => toggleLoading())
          }}
        />
      </div>
    </OnboardingContainer>
  )
}

export default LoginPage
