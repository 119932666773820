import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import {
  useDocumentLinking,
  useDocuments,
} from '../../hooks/document/useDocuments'
import { Document } from '../../types/graphql-types'
import _ from 'lodash'
import { PageContent } from '../templates/page'
import DocumentsBrowser from '../organism/documents-browser'

const MeetingDetailsDocuments = () => {
  const { id } = useParams()
  const { page: meetingDocuments } = useDocuments({
    meetingId: id!!,
    limit: 100,
  })
  const [selectedDocuments, setSelectedDocuments] = useState<Set<Document>>(
    new Set(),
  )
  const { linkDocumentToMeeting, unlinkDocumentFromMeeting } =
    useDocumentLinking()

  useEffect(() => {
    if (meetingDocuments?.data?.length) {
      const documents = meetingDocuments.data
      setSelectedDocuments(new Set(documents))
    }
  }, [meetingDocuments])


  return (
    <PageContent>
      <DocumentsBrowser
        selectedDocuments={selectedDocuments}
        onSelectDocumentsChange={docs => {
          _.difference([...selectedDocuments], [...docs]).forEach(doc =>
            unlinkDocumentFromMeeting(doc.id, id!!),
          )
          _.difference([...docs], [...selectedDocuments]).forEach(doc =>
            linkDocumentToMeeting(doc.id, id!!),
          )
          setSelectedDocuments(docs)
        }}
      />
    </PageContent>
  )
}

export default MeetingDetailsDocuments
