import { capitalize } from 'lodash'

export const encodeParams = (params: Record<string, any>) =>
  Object.entries(params)
    .filter(([_, value]) => !!value || typeof value === 'boolean')
    .map(([key, value]) => [
      key,
      Array.isArray(value) ? value.join(',') : value,
    ])
    .map(([key, value]) => [key, value].map(encodeURIComponent).join('='))
    .join('&')

export const humanize = (val: string | undefined) =>
  val ? capitalize(val).replaceAll('_', ' ') : ''

export const extensionFrom = (file: File) => file.name.split('.').pop()

export const omitUndefined = (obj: any): any =>
  Object.entries(obj)
    .filter(([_, v]) => v !== undefined)
    .reduce((toObject, [k, v]) => ({ ...toObject, [k]: v }), {})

export const removeTypename = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(removeTypename)
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      if (key !== '__typename') {
        acc[key] = removeTypename(obj[key])
      }
      return acc
    }, {} as any)
  }
  return obj
}
