import { gql } from '@apollo/client'
import useQuery from '../useQuery'
import {
  Meeting,
  MeetingsRequestFilterInput,
  NewMeetingInput, UpdateMeetingInput,
} from '../../types/graphql-types'
import useMutation from '../useMutation'
import { Page } from '../../types/pageable'
import { noop } from 'lodash'

const meetingsQuery = gql`
  query Meetings($filter: MeetingsRequestFilterInput!) {
    meetings(filter: $filter) {
      data {
        id
        name
        location
        locationType
        startTime
        endTime
        status
        createdAt
        updatedAt
      }
      next
    }
  }
`
export const useMeetings = (filter: MeetingsRequestFilterInput) => {
  const { data, loading, error, refetch } = useQuery(meetingsQuery, {
    variables: { filter },
  })

  return {
    page: data?.meetings as Page<Meeting>,
    loading,
    error,
    refetch: () => refetch().then(noop),
  }
}

const meetingQuery = gql`
  query Meeting($id: String!) {
    meeting(id: $id) {
      id
      name
      status
      startTime
      endTime
      location
      locationType
      recordingUrl
      transcriptUrl
      transcriptFormat
      summaryUrl
      agenda
      participants {
        id
        participant {
          id
          name
          email
          role
        }
      }
      createdAt
      updatedAt
    }
  }
`

export const useMeeting = (id: string) => {
  const { data, loading, error, refetch } = useQuery(meetingQuery, {
    variables: { id },
  })

  const meeting = data?.meeting as Meeting | undefined

  return { meeting, loading, error, refetch: () => refetch().then(noop) }
}

const createMeetingMutation = gql`
  mutation CreateMeeting($meeting: NewMeetingInput!) {
    createMeeting(meeting: $meeting) {
      id
      name
      status
      createdAt
      updatedAt
    }
  }
`

export const useCreateMeeting = () => {
  const [createMeetingFn] = useMutation(createMeetingMutation)

  return (meeting: NewMeetingInput): Promise<Meeting> =>
    createMeetingFn({ variables: { meeting } }).then(
      ({ data }) => data.createMeeting,
    )
}

const updateMeetingMutation = gql`
  mutation UpdateMeeting($id: String!, $meeting: UpdateMeetingInput!) {
    updateMeeting(id: $id, meeting: $meeting) {
      id
      name
      status
      createdAt
      updatedAt
    }
  }
`
const addParticipantsMutation = gql`
  mutation AddMeetingParticipants($id: String!, $participants: [String!]!) {
    addMeetingParticipants(id: $id, participants: $participants)
  }
`

const removeParticipantsMutation = gql`
  mutation RemoveMeetingParticipants($id: String!, $participants: [String!]!) {
    removeMeetingParticipants(id: $id, participants: $participants)
  }
`

export const useUpdateMeeting = () => {
  const [updateMeeting] = useMutation(updateMeetingMutation)
  const [addParticipants] = useMutation(addParticipantsMutation)
  const [removeParticipants] = useMutation(removeParticipantsMutation)

  return {
    updateMeeting: (id: string, meeting: UpdateMeetingInput) =>
      updateMeeting({ variables: { id, meeting } }),
    addParticipants: (id: string, participants: string[]) =>
      addParticipants({ variables: { id, participants } }),
    removeParticipants: (id: string, participants: string[]) =>
      removeParticipants({ variables: { id, participants } }),
  }
}


const joinMeetingMutation = gql`
  mutation JoinMeeting($id: String!) {
    joinMeeting(id: $id)
  }
`
export const useJoinMeeting = (id: string) => {
  const [joinMeeting] = useMutation(joinMeetingMutation)

  return () => joinMeeting({ variables: { id } })
}
