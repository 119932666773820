import { gql } from '@apollo/client'
import { noop } from 'lodash'
import {
  AssociationMember,
  AssociationMemberUpdateInput,
  NewAssociationMemberInput,
} from '../../types/graphql-types'
import useQuery from '../useQuery'
import useMutation from '../useMutation'

const associationMembersQuery = gql`
  query AssociationMembers {
    associationMembers {
      id
      name
      email
      role
      status
      createdAt
      updatedAt
    }
  }
`

const addAssociationMemberMutation = gql`
  mutation AddAssociationMember($newMember: NewAssociationMemberInput!) {
    addAssociationMember(newMember: $newMember) {
      id
      name
      email
      role
      status
      createdAt
      updatedAt
    }
  }
`

const updateAssociationMemberMutation = gql`
  mutation UpdateAssociationMember($member: AssociationMemberUpdateInput!) {
    updateAssociationMember(member: $member) {
      id
      name
      email
      role
      status
      createdAt
      updatedAt
    }
  }
`

const deactivateAssociationMemberMutation = gql`
  mutation DeactivateAssociationMember($id: String!) {
    deactivateAssociationMember(memberId: $id)
  }
`

export const useAssociationMembers = () => {
  const { loading, data, refetch } = useQuery<{
    associationMembers: AssociationMember[]
  }>(associationMembersQuery)

  return {
    loading,
    members: data?.associationMembers ?? [],
    refetch: () => refetch().then(noop),
  }
}

export const useAddAssociationMember = () => {
  const [addMember] = useMutation(addAssociationMemberMutation)

  return (newMember: NewAssociationMemberInput) =>
    addMember({ variables: { newMember } })
}

export const useUpdateAssociationMember = () => {
  const [updateMember] = useMutation(updateAssociationMemberMutation)

  return (member: AssociationMemberUpdateInput) =>
    updateMember({ variables: { member } })
}

export const useDeactivateAssociationMember = () => {
  const [deactivateMember] = useMutation(deactivateAssociationMemberMutation)

  return (id: string) => deactivateMember({ variables: { id } })
}
