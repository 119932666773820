import 'video.js/dist/video-js.css'
import './video-player.css'
import React, { useEffect, useRef, useState } from 'react'
import videojs from 'video.js'
import Player from 'video.js/dist/types/player'

interface VideoPlayerProps {
  url: string
  onTimeUpdate: (time: number) => void
  setPlayerRef?: (player: Player) => void
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  url,
  onTimeUpdate,
  setPlayerRef,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const playerRef = useRef<Player | null>(null)
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [])

  useEffect(() => {
    if (isMounted && videoRef.current && !playerRef.current) {
      const player = videojs(videoRef.current, {
        controls: true,
        autoplay: false,
        preload: 'auto',
        fluid: true,
        aspectRatio: '16:9',
        playbackRates: [0.75, 1, 1.5, 2],
        controlBar: {
          pictureInPictureToggle: false,
          skipButtons: { backward: 5, forward: 10 },
        },
      })

      player.src(url)
      playerRef.current = player
      if (setPlayerRef) {
        setPlayerRef(player)
      }

      player.on('timeupdate', () => {
        const currentTime = player.currentTime()
        if (typeof currentTime === 'number') {
          onTimeUpdate(currentTime)
        }
      })

      return () => {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [url, onTimeUpdate, isMounted, setPlayerRef])

  return (
    <div data-vjs-player>
      <video
        style={{ borderRadius: '10px' }}
        ref={videoRef}
        className="video-js vjs-big-play-centered vjs-playback-rate"
        playsInline
        height={300}
      />
    </div>
  )
}

export default VideoPlayer
