import React from 'react'
import { Page, PageContent, PageHeader } from '../templates/page'
import { Typography } from 'antd'
import RouteAwareTabs from '../organism/route-aware-tabs'
import { AssociationSettingsSubRoutes } from '../../router'

const { Title } = Typography

const OrganizationSettingsPage = () => (
  <Page>
    <PageHeader>
      <Title level={3}>Settings</Title>
    </PageHeader>
    <PageContent>
      <RouteAwareTabs
        basePath="/settings/"
        tabs={AssociationSettingsSubRoutes.map(r => ({
          path: r.path as string,
          label: r.label,
        }))}
      />
    </PageContent>
  </Page>
)

export default OrganizationSettingsPage
