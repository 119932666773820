import React, { useEffect, useMemo, useRef, useState } from 'react'
import { RawTranscriptEntry, TranscriptEntry } from '../../types/transcript'
import { List, Typography } from 'antd'
import _ from 'lodash'
import Loading from '../atoms/loading'
import useDebounce from '../../hooks/useDebounce'
import MiniSearch, { SearchResult } from 'minisearch'
import { MeetingTranscriptFormat } from '../../types/graphql-types'

type TranscriptProps = {
  transcriptUrl: string
  transcriptFormat: MeetingTranscriptFormat
  currentTime: number
  onWordClick: (time: number) => void
  searchQuery?: string
}
// TODO move over transcription-handler function
type VttBlock = {
  cueNumber: number
  startTime: string
  endTime: string
  speaker?: string
  text: string
}

const extractVttBlocks = (vttContent: string): VttBlock[] => {
  const vttBlocks: VttBlock[] = []
  const lines = vttContent.split('\n')
  let currentBlock: Partial<VttBlock> = {}
  let isText = false
  let textBuffer = ''

  // Regular expression to detect speaker (e.g., "Speaker Name:")
  const speakerRegex = /^([A-Za-z\s]+):\s*(.*)$/

  for (const line of lines) {
    const trimmedLine = line.trim()

    if (trimmedLine === '') {
      // Empty line signifies the end of a block
      if (currentBlock.startTime && currentBlock.endTime && textBuffer) {
        let speaker: string | undefined = undefined
        let text = textBuffer.trim()

        // Check if the first line contains a speaker name
        const speakerMatch = text.match(speakerRegex)
        if (speakerMatch) {
          speaker = speakerMatch[1].trim() // Extract speaker name
          text = speakerMatch[2].trim() // Rest of the text after the speaker name
        }

        vttBlocks.push({
          cueNumber: currentBlock.cueNumber ?? 0,
          startTime: currentBlock.startTime,
          endTime: currentBlock.endTime,
          speaker, // Add the speaker if available
          text: text.replace(/\n/g, ' '), // Flatten multiline text into one line
        })
      }
      // Reset for the next block
      currentBlock = {}
      textBuffer = ''
      isText = false
    } else if (/^\d+$/.test(trimmedLine)) {
      // Cue number
      currentBlock.cueNumber = parseInt(trimmedLine, 10)
    } else if (trimmedLine.includes('-->')) {
      // Timecode line
      const [startTime, endTime] = trimmedLine.split(' --> ')
      currentBlock.startTime = startTime.trim()
      currentBlock.endTime = endTime.trim()
      isText = true
    } else if (isText) {
      // Collecting text for the current block
      textBuffer += `${trimmedLine}\n` // Preserve new lines for now
    }
  }

  // Add the last block (in case the content doesn't end with a blank line)
  if (currentBlock.startTime && currentBlock.endTime && textBuffer) {
    let speaker: string | undefined = undefined
    let text = textBuffer.trim()

    // Check if the first line contains a speaker name
    const speakerMatch = text.match(speakerRegex)
    if (speakerMatch) {
      speaker = speakerMatch[1].trim() // Extract speaker name
      text = speakerMatch[2].trim() // Rest of the text after the speaker name
    }

    vttBlocks.push({
      cueNumber: currentBlock.cueNumber ?? 0,
      startTime: currentBlock.startTime,
      endTime: currentBlock.endTime,
      speaker, // Add the speaker if available
      text: text.replace(/\n/g, ' '), // Flatten multiline text into one line
    })
  }

  return vttBlocks
}

const parseVTT = (vttContent: string): RawTranscriptEntry[] =>
  extractVttBlocks(vttContent).map(block => {
    const words = block.text.split(' ').filter(w => w.trim())
    const wordsWithTiming = words.map((word, index) => ({
      word,
      start:
        parseTimeToSeconds(block.startTime) +
        (index *
          (parseTimeToSeconds(block.endTime) -
            parseTimeToSeconds(block.startTime))) /
          words.length,
      end:
        parseTimeToSeconds(block.startTime) +
        ((index + 1) *
          (parseTimeToSeconds(block.endTime) -
            parseTimeToSeconds(block.startTime))) /
          words.length,
    }))

    return {
      speaker: 'Unknown Speaker', // Default speaker if not provided
      words: wordsWithTiming,
    }
  })

// Helper function to convert VTT time to seconds
const parseTimeToSeconds = (time: string): number => {
  const parts = time.split(':')
  const [hours, minutes] = parts.map(parseFloat)
  const seconds = parseFloat(parts[2])
  return hours * 3600 + minutes * 60 + seconds
}

type WordProps = {
  word: string
  isPartOfResult: boolean
  isHighlighted: boolean
  onClick: () => void
}

const Word: React.FC<WordProps> = ({
  word,
  isPartOfResult,
  isHighlighted,
  onClick,
}) => (
  <span
    style={{
      backgroundColor: isHighlighted
        ? '#393E41'
        : isPartOfResult
          ? '#F9C784'
          : 'transparent',
      color: isHighlighted ? 'white' : 'black',
      cursor: 'pointer',
      borderRadius: '4px',
      padding: '0 4px',
      margin: '0 -4px 0 0',
    }}
    onClick={onClick}
  >
    {word}
  </span>
)

const Transcript: React.FC<TranscriptProps> = ({
  transcriptUrl,
  transcriptFormat,
  currentTime,
  onWordClick,
  searchQuery = '',
}) => {
  const debouncedQuery = useDebounce<string>(searchQuery, 200)
  const [loading, setLoading] = useState(true)
  const [transcriptIndexed, setTranscriptIndexed] = useState(false)
  const [transcript, setTranscript] = useState<TranscriptEntry[]>([])
  const highlightedWordRef = useRef<HTMLSpanElement>(null)
  const [filteredTranscript, setFilteredTranscript] = useState<
    TranscriptEntry[]
  >([])
  const search = useRef<MiniSearch<TranscriptEntry> | null>(null)
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])
  const transcriptEntriesById = useMemo(
    () => _.keyBy(transcript, entry => entry.id),
    [transcript],
  )

  useEffect(() => {
    setLoading(true)
    fetch(transcriptUrl)
      .then(response =>
        transcriptFormat === MeetingTranscriptFormat.Vtt
          ? response.text().then(parseVTT)
          : response.json(),
      )
      .then(t => {
        const augmentedTranscript = t.map(
          (entry: any, index: number) =>
            ({
              ...entry,
              id: index,
              sentence: entry.words.map((word: any) => word.word).join(' '),
            }) as TranscriptEntry,
        )
        setTranscript(augmentedTranscript)
        setFilteredTranscript(augmentedTranscript)
      })
      .finally(() => setLoading(false))
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcriptUrl])

  useEffect(() => {
    if (!transcript.length || transcriptIndexed) return

    search.current = new MiniSearch({
      idField: 'id',
      fields: ['sentence'],
      searchOptions: { fuzzy: 0.2 },
    })
    search.current
      ?.addAllAsync(transcript)
      .then(() => setTranscriptIndexed(true))
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcript])

  useEffect(() => {
    if (highlightedWordRef.current) {
      highlightedWordRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime])

  useEffect(() => {
    if (!!debouncedQuery && !!search.current && transcriptIndexed) {
      console.time('search')
      const results = search.current.search(debouncedQuery)
      console.timeEnd('search')
      setSearchResults(results)
      setFilteredTranscript(
        results.map(result => transcriptEntriesById[result.id]),
      )
    } else {
      setFilteredTranscript(transcript)
      setSearchResults([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery, search.current, transcriptIndexed])

  return loading ? (
    <Loading />
  ) : (
    <List
      itemLayout="vertical"
      split={false}
      bordered={false}
      dataSource={filteredTranscript.map(entry => ({
        ...entry,
        words: _.uniqBy(entry.words, 'start'),
      }))}
      renderItem={item => (
        <List.Item>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '6px',
              width: '100%',
              userSelect: 'none',
            }}
          >
            <Typography.Title level={5}>{item.speaker}</Typography.Title>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '2px' }}>
              {item.words.map((word, index) => {
                const isHighlighted =
                  currentTime >= word.start && currentTime <= word.end
                const isPartOfResult = searchResults.some(
                  result =>
                    (result.id === item.id &&
                      result.terms.includes(word.word.toLowerCase())) ||
                    word.word.toLowerCase().includes(result.terms.join(' ')),
                )
                return (
                  <Word
                    key={index}
                    word={word.word}
                    isPartOfResult={isPartOfResult}
                    isHighlighted={isHighlighted}
                    onClick={() => onWordClick(word.start)}
                  />
                )
              })}
            </div>
          </div>
        </List.Item>
      )}
    />
  )
}

export default Transcript
