import React, { useContext, useEffect, useState } from 'react'
import useDebounce from '../../hooks/useDebounce'
import { useQueryParam } from '../../hooks/useQueryParam'
import {
  OrganizationMember,
  OrganizationMemberRole,
  OrganizationMemberStatus,
} from '../../types/graphql-types'
import {
  useInviteMember,
  useOrganizationMembers,
} from '../../hooks/organization/useOrganization'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { OrganizationContext } from '../../context/organization-context'
import Spacer from '../atoms/spacer'
import Loading from '../atoms/loading'
import {
  Col,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Transfer,
  Typography,
} from 'antd'
import Title from 'antd/lib/typography/Title'
import { SearchOutlined } from '@ant-design/icons'
import { humanize } from '../../utils/utils'
import { WarningIcon } from '../atoms/icon'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useNavigate } from 'react-router-dom'
import { useBoolean } from '../../hooks/useBoolean'
import { Label } from '../atoms/typography'
import Button from '../atoms/button'
import { useAssociations } from '../../hooks/association/useAssociation'
import { InputLabel, InputLabelContainer } from '../atoms/input'

const roleOptions = Object.values(OrganizationMemberRole).map(role => {
  return { value: role, label: role }
})

interface InviteFormValues {
  email: string
  role: OrganizationMemberRole
}

const OrganizationMembers: React.FC = () => {
  useDocumentTitle('Settings - Members')
  const push = useNavigate()
  const [form] = Form.useForm()
  const org = useContext(OrganizationContext)!.membership!.organization
  const { associations } = useAssociations()
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce<string>(query, 300)
  const [after, setAfter] = useState<number>()
  const [status, setStatus] = useQueryParam<OrganizationMemberStatus>('sta')
  const [role, setRole] = useQueryParam<OrganizationMemberRole>('rol')
  const [inviting, setInviting] = useQueryParam<any>('new', undefined, true)
  const [members, setMembers] = useState<OrganizationMember[]>([])
  const [modalOpened, toggleModal] = useBoolean(!!inviting)
  const [xhrPending, toggleXhrPending] = useBoolean()
  const inviteMember = useInviteMember()
  const [invitingAssociationIds, setInvitingAssociationIds] = useState<
    string[]
  >([])

  const { loading, page, refetch } = useOrganizationMembers({
    orgId: org.id,
    query: debouncedQuery,
    status,
    role,
    after,
  })
  const hasNextPage = (page?.next ?? 0) > 0
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setAfter(page?.next),
    disabled: !loading && !page,
    rootMargin: '0px 0px 400px 0px',
  })

  const sendInvite = (values: InviteFormValues) => {
    toggleXhrPending()
    inviteMember({
      identifier: values.email,
      orgId: org.id,
      role: values.role,
      associationIds: invitingAssociationIds,
    })
      .then(result => {
        if (result) refetch()
      })
      .then(() => form.resetFields())
      .then(toggleModal)
      .finally(toggleXhrPending)
  }

  const submitInviteForm = () => {
    form.validateFields().then((values: InviteFormValues) => {
      if (invitingAssociationIds.length === 0) return
      sendInvite(values)
    })
  }

  useEffect(() => {
    if (page) {
      const data = page?.data ?? []
      setMembers(prev => (after ? [...prev, ...data] : data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    setAfter(undefined)
  }, [debouncedQuery, status, role])

  useEffect(() => {
    if (!modalOpened) setInviting(undefined)
    // eslint-disable-next-line
  }, [modalOpened])

  return (
    <>
      <Row align="middle" justify="space-between">
        <Flex style={{ width: '50%' }} gap={12} align="center">
          <Input
            prefix={<SearchOutlined />}
            value={query}
            onChange={event => setQuery(event.target.value)}
            allowClear
            style={{ width: '33%' }}
          />
          <Select
            placeholder="Status"
            value={status}
            allowClear
            style={{ width: '33%' }}
            onChange={val => setStatus(val as OrganizationMemberStatus)}
            options={Object.values(OrganizationMemberStatus).map(value => ({
              label: humanize(value),
              value,
            }))}
          />
          <Select
            placeholder="Role"
            value={role}
            allowClear
            style={{ width: '33%' }}
            onChange={val => setRole(val as OrganizationMemberRole)}
            options={Object.values(OrganizationMemberRole).map(value => ({
              label: humanize(value),
              value,
            }))}
          />
        </Flex>
        <Col>
          <Button
            analyticsId="invite_organization_member"
            label="+ invite"
            onClick={toggleModal}
          />
        </Col>
      </Row>

      <Spacer size="large" />
      <Table
        rowKey={item => (item as OrganizationMember).id.identifier}
        columns={[
          { title: 'Identifier', dataIndex: 'identifier' },
          { title: 'Role', dataIndex: 'role' },
          { title: 'Status', dataIndex: 'status' },
        ]}
        dataSource={members.map(member => ({
          ...member,
          identifier: member.id.identifier,
          link: `/organization/members/${member.id.identifier}`,
        }))}
        onRow={(record: any) => ({
          style: { cursor: 'pointer' },
          onClick: () => push(record.link),
        })}
        pagination={false}
      />
      <Modal
        open={modalOpened}
        onCancel={toggleModal}
        cancelText="cancel"
        footer={null}
      >
        <Title level={2}>Invite team member</Title>
        <Spacer />
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Space direction="horizontal" size="middle">
            <WarningIcon />
            <Typography.Text>
              To enhance the user experience, we support registrations and
              logins exclusively with <Label strong>Gmail</Label> or{' '}
              <Label strong>Google Workspace accounts</Label>.
            </Typography.Text>
          </Space>
          <Form form={form} onFinish={submitInviteForm}>
            <Form.Item<InviteFormValues>
              name="email"
              label="Email"
              rules={[
                { required: true, message: 'Please enter an email' },
                { whitespace: true, message: 'Email cannot be empty' },
              ]}
            >
              <Input
                placeholder="eg. jdoe@myrna.io"
                onPressEnter={submitInviteForm}
                required
              />
            </Form.Item>
            <Form.Item<InviteFormValues>
              name="role"
              label="Role"
              initialValue={OrganizationMemberRole.User}
              rules={[
                {
                  required: true,
                  message: 'Please select a role for this member',
                },
              ]}
            >
              <Select options={roleOptions} />
            </Form.Item>

            <InputLabelContainer>
              <InputLabel>Associations</InputLabel>
              <Transfer
                style={{
                  flex: 1,
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
                listStyle={{ width: '47%', height: '220px' }}
                dataSource={associations.map(a => ({
                  key: a.id,
                  title: a.name,
                }))}
                targetKeys={invitingAssociationIds}
                onChange={keys => setInvitingAssociationIds(keys as string[])}
                locale={{
                  searchPlaceholder: 'Search for associations',
                  itemUnit: '',
                  itemsUnit: '',
                }}
                titles={['Hidden', 'Authorized']}
                render={item => item.title}
                oneWay
              />
            </InputLabelContainer>
            <Spacer />
            <Row justify="end">
              <Col>
                <Form.Item>
                  <Button
                    analyticsId="send_member_invite"
                    label="send"
                    onClick={submitInviteForm}
                    loading={xhrPending}
                    disabled={invitingAssociationIds.length === 0}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Space>
      </Modal>

      {hasNextPage && <div ref={sentryRef} />}
      {loading && members.length > 0 && (
        <Spacer>
          <Loading />
        </Spacer>
      )}
    </>
  )
}

export default OrganizationMembers
