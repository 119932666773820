import { Button, Card, Col, Flex, Input, List, Modal, Row, Select } from 'antd'
import { Document, DocumentType } from '../../types/graphql-types'
import { humanize } from '../../utils/utils'
import { CheckCircleOutlined, CloudUploadOutlined } from '@ant-design/icons'
import { Document as PdfDocument, Thumbnail } from 'react-pdf'
import Spacer from '../atoms/spacer'
import Loading from '../atoms/loading'
import React, { useContext, useEffect, useRef, useState } from 'react'
import useInput from '../../hooks/useInput'
import useDebounce from '../../hooks/useDebounce'
import {
  useCreateDocument,
  useDocuments,
} from '../../hooks/document/useDocuments'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import _, { noop } from 'lodash'
import { useBoolean } from '../../hooks/useBoolean'
import useFileUpload from '../../hooks/useFileUpload'
import FileUploader, { FileTypes } from './file-uploader'
import { AssociationContext } from '../../context/association-context'

type DocumentCardProps = {
  item: Document
  selectedDocuments: Set<any>
  onSelectDocumentsChange?: (selected: Set<any>) => void
}

const DocumentCard: React.FC<DocumentCardProps> = ({
  item,
  selectedDocuments,
  onSelectDocumentsChange,
}) => {
  const cardRef = useRef<HTMLDivElement>(null)
  const [thumbnailWidth, setThumbnailWidth] = useState<number | undefined>(
    undefined,
  )

  const updateThumbnailWidth = () => {
    if (cardRef.current) {
      const parentWidth = cardRef.current.clientWidth
      setThumbnailWidth(parentWidth * 0.97)
    }
  }

  useEffect(() => {
    updateThumbnailWidth()
    window.addEventListener('resize', updateThumbnailWidth)
    return () => {
      window.removeEventListener('resize', updateThumbnailWidth)
    }
  }, [])

  return (
    <Card
      ref={cardRef}
      title={item.name}
      hoverable={!!onSelectDocumentsChange}
      extra={selectedDocuments.has(item) ? <CheckCircleOutlined /> : undefined}
      style={{
        border: selectedDocuments.has(item) ? '2px solid #151516' : '',
      }}
      styles={{ body: { padding: 3 } }}
    >
      <PdfDocument file={item.url}>
        <Thumbnail pageNumber={1} width={thumbnailWidth} />
      </PdfDocument>
    </Card>
  )
}

type DocumentBrowserProps = {
  selectedDocuments?: Set<Document>
  onSelectDocumentsChange?: (documents: Set<Document>) => void
}

const DocumentsBrowser: React.FC<DocumentBrowserProps> = ({
  selectedDocuments = new Set(),
  onSelectDocumentsChange,
}) => {
  const { association } = useContext(AssociationContext)
  const [query, setQuery] = useInput('')
  const debouncedQuery = useDebounce<string>(query ?? '', 300)
  const [type, setType] = useState<DocumentType>()
  const [after, setAfter] = useState<number>()
  const { loading, page, refetch } = useDocuments({
    query: debouncedQuery,
    type,
    after,
  })
  const [documents, setDocuments] = useState<Document[]>([])
  const hasNextPage = (page?.next ?? 0) > 0
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setAfter(page?.next),
    disabled: !loading && !page,
    rootMargin: '0px 0px 400px 0px',
  })
  const createDocument = useCreateDocument()
  const [uploadModalOpened, toggleUploadModal] = useBoolean()
  const [documentFilesToUpload, setDocumentFilesToUpload] = useState<File[]>([])
  const [uploading, toggleUploading] = useBoolean()
  const { upload } = useFileUpload()

  useEffect(() => {
    if (page) {
      const data = page?.data ?? []
      setDocuments(prev => (after ? [...prev, ...data] : data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    setAfter(undefined)
  }, [debouncedQuery])

  return (
    <>
      <Modal
        title="Upload a new document"
        open={uploadModalOpened}
        onCancel={toggleUploadModal}
        width="50%"
        confirmLoading={uploading}
        onOk={() => {
          if (documentFilesToUpload.length > 0) {
            toggleUploading()
            Promise.all(
              documentFilesToUpload.map(file => {
                const filename = `organizations/${association?.orgId}/associations/${association?.id}/documents/${new Date().toISOString()}-${file.name}`
                return upload(file, filename).then(() =>
                  createDocument([{ name: file.name, storagePath: filename }]),
                )
              }),
            )
              .then(refetch)
              .then(() => {
                setDocumentFilesToUpload([])
                toggleUploadModal()
              })
              .finally(toggleUploading)
          }
        }}
      >
        <Spacer>
          <FileUploader
            supportedFileTypes={[FileTypes.PDF]}
            onChange={setDocumentFilesToUpload}
          />
        </Spacer>
      </Modal>
      <Flex vertical gap={24}>
        <Row align="middle" justify="space-between">
          <Col span={12}>
            <Row gutter={24}>
              <Col>
                <Input
                  placeholder="search by name"
                  value={query}
                  onChange={setQuery}
                />
              </Col>
              <Col>
                <Select
                  style={{ width: '100%' }}
                  placeholder="Document Types"
                  onChange={setType}
                  allowClear
                  options={Object.values(DocumentType).map(value => ({
                    label: humanize(value),
                    value,
                  }))}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Button type="primary" onClick={toggleUploadModal}>
              <CloudUploadOutlined /> Upload
            </Button>
          </Col>
        </Row>
        <List
          grid={{ gutter: 16, column: 4 }}
          loading={loading && documents.length === 0}
          dataSource={_.sortBy(
            _.uniqBy([...selectedDocuments, ...documents], 'id'),
            'name',
          )}
          renderItem={item => (
            <List.Item
              onClick={
                !onSelectDocumentsChange
                  ? noop
                  : () =>
                      onSelectDocumentsChange(
                        new Set(
                          selectedDocuments.has(item)
                            ? [...selectedDocuments].filter(doc => doc !== item)
                            : [...selectedDocuments, item],
                        ),
                      )
              }
            >
              <DocumentCard
                item={item}
                selectedDocuments={selectedDocuments}
                onSelectDocumentsChange={onSelectDocumentsChange}
              />
            </List.Item>
          )}
        />
        {hasNextPage && <div ref={sentryRef} />}
        {loading && documents.length > 0 && (
          <Spacer>
            <Loading />
          </Spacer>
        )}
      </Flex>
    </>
  )
}

export default DocumentsBrowser
