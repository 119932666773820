import React, { useContext } from 'react'
import OnboardingContainer from '../templates/onboarding'
import { useParams } from 'react-router-dom'
import { Divider, Space, Typography } from 'antd'
import Button from '../atoms/button'
import { GoogleSignIn } from '../atoms/google-sign-in-button'
import XhrPending from '../atoms/xhr-pending'
import {
  useAcceptOrganizationInvite,
  useDeclineOrganizationInvite,
  useInvitation,
} from '../../hooks/organization/useOrganization'
import { SecurityContext } from '../../context/security-context'
import { t } from 'i18next'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

const InviteNotFoundOrExpiredState = () => (
  <OnboardingContainer title="Invitation not found">
    <Space direction="vertical" size="large">
      <Typography.Text>{t('invitations_invalid_expired')}</Typography.Text>
      <Typography.Text>{t('invitations_use_correct')}</Typography.Text>
    </Space>
  </OnboardingContainer>
)

type UnauthenticatedStateProps = {
  login: () => void
}
const UnauthenticatedState: React.FC<UnauthenticatedStateProps> = ({
  login,
}) => (
  <OnboardingContainer title={t('invitations_signin')}>
    <GoogleSignIn onClick={login} />
  </OnboardingContainer>
)

type ValidInviteStateProps = {
  orgName: string
  email: string
  handleAccept: () => void
  handleDecline: () => void
}
const ValidInviteState: React.FC<ValidInviteStateProps> = ({
  orgName,
  email,
  handleAccept,
  handleDecline,
}) => (
  <OnboardingContainer title={`You have been invited to join ${orgName}`}>
    <Space direction="vertical" size="large">
      <Typography.Text>
        Your account <Typography.Text italic>{email}</Typography.Text> will be
        linked to this account.
      </Typography.Text>
      <div>
        <Button
          analyticsId="accept_invite"
          onClick={handleAccept}
          label="Accept invite"
        />
        <Divider>or</Divider>
        <Button
          analyticsId="decline_invite"
          onClick={handleDecline}
          type="default"
          label="Decline"
        />
      </div>
    </Space>
  </OnboardingContainer>
)

type ImpersonatingStateProps = {
  logout: () => void
}
const ImpersonatingState: React.FC<ImpersonatingStateProps> = ({ logout }) => (
  <OnboardingContainer title="Invitation intended for a different account">
    <Space direction="vertical" size="large">
      <Typography.Text>{t('invitations_logout')}</Typography.Text>
      <Button analyticsId="logout_invite" onClick={logout} label="logout" />
    </Space>
  </OnboardingContainer>
)

const InvitePage = () => {
  useDocumentTitle('Invitation')
  const { account, login, logout } = useContext(SecurityContext)
  const { id } = useParams<{ id: string }>()
  const acceptInvite = useAcceptOrganizationInvite()
  const declineInvite = useDeclineOrganizationInvite()
  const { loading, data, error } = useInvitation(id!)

  const handleAccept = () => {
    acceptInvite(id!, {
      identifier: data?.id.identifier!,
      name: data?.name ?? account?.name!!,
      email: data?.id.identifier!,
    }).then(result => {
      if (result) {
        window.location.pathname = '/'
      }
    })
  }

  const handleDecline = () => {
    declineInvite(id!).then(result => {
      if (result) {
        window.location.pathname = '/'
      }
    })
  }

  return (
    <XhrPending pending={loading}>
      {!account ? (
        <UnauthenticatedState login={login} />
      ) : error ? (
        <InviteNotFoundOrExpiredState />
      ) : !!data && account.identifier === data.id.identifier ? (
        <ValidInviteState
          orgName={data.organization.name}
          email={data.id.identifier}
          handleAccept={handleAccept}
          handleDecline={handleDecline}
        />
      ) : (
        <ImpersonatingState logout={logout} />
      )}
    </XhrPending>
  )
}

export default InvitePage
