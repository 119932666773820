import { Col, Flex, Row, Switch, Table, TableProps, Tag } from 'antd'
import React, { useState } from 'react'
import {
  useAddAssociationMember,
  useAssociationMembers,
  useDeactivateAssociationMember,
  useUpdateAssociationMember,
} from '../../hooks/association/useAssociationMembers'
import {
  AssociationMember,
  AssociationMemberStatus,
  AssociationMemberUpdateInput,
} from '../../types/graphql-types'
import { humanize } from '../../utils/utils'
import Button from '../atoms/button'
import { EditOutlined } from '@ant-design/icons'
import Spacer from '../atoms/spacer'
import CreateEditAssociationMemberModal from '../organism/association-member-modal'

const AssociationMembers = () => {
  const { loading, members, refetch } = useAssociationMembers()
  const addMember = useAddAssociationMember()
  const updateMember = useUpdateAssociationMember()
  const deactivateMember = useDeactivateAssociationMember()
  const [editingMember, setEditingMember] =
    useState<AssociationMemberUpdateInput>()

  const columns: TableProps<AssociationMember>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      render: (_, { role }) => <Tag key={role}>{humanize(role)}</Tag>,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, { id, status }) => (
        <Flex gap={12} align="center">
          <Tag
            key={status}
            color={status === AssociationMemberStatus.Active ? 'green' : 'red'}
          >
            {humanize(status)}
          </Tag>
          <Switch
            checked={status === AssociationMemberStatus.Active}
            onChange={checked => {
              const promise = checked
                ? updateMember({ id, status: AssociationMemberStatus.Active })
                : deactivateMember(id)
              promise.then(refetch)
            }}
          />
        </Flex>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Button
          analyticsId="association_member_open_edit_modal"
          type="text"
          icon={<EditOutlined />}
          onClick={() =>
            setEditingMember({
              id: record.id,
              name: record.name,
              email: record.email,
              role: record.role,
            })
          }
        />
      ),
    },
  ]

  return (
    <>
      {editingMember && (
        <CreateEditAssociationMemberModal
          member={editingMember}
          onCancel={() => setEditingMember(undefined)}
          onSave={member => {
            const promise = !!editingMember.id
              ? updateMember({
                  id: editingMember.id,
                  name: member.name,
                  email: member.email,
                  role: member.role,
                })
              : addMember({
                  name: member.name!!,
                  email: member.email,
                  role: member.role!!,
                })
            return promise.then(refetch).then(() => setEditingMember(undefined))
          }}
        />
      )}
      <Row justify="end">
        <Col>
          <Button
            analyticsId="association_member_open_create_edit_modal"
            label="+ Add new member"
            onClick={() => setEditingMember({ id: '' })}
          />
        </Col>
      </Row>
      <Spacer size="large" />
      <Table
        rowKey={item => item.id}
        loading={loading}
        columns={columns}
        dataSource={members}
        pagination={false}
      />
    </>
  )
}

export default AssociationMembers
