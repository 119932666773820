import {
  AssociationMemberRole,
  AssociationMemberUpdateInput,
  NewAssociationMemberInput,
} from '../../types/graphql-types'
import React, { useState } from 'react'
import useInput from '../../hooks/useInput'
import { Flex, Input, Modal, Select } from 'antd'
import { InputLabelContainer } from '../atoms/input'
import { humanize } from '../../utils/utils'

type CreateEditAssociationMemberModalProps = {
  member?: AssociationMemberUpdateInput
  onSave: (
    member: NewAssociationMemberInput | AssociationMemberUpdateInput,
  ) => Promise<void>
  onCancel: () => void
}
const CreateEditAssociationMemberModal: React.FC<
  CreateEditAssociationMemberModalProps
> = ({ member, onSave, onCancel }) => {
  const isNew = !member?.id
  const [loading, setLoading] = useState(false)
  const [name, setName] = useInput(member?.name ?? '')
  const [email, setEmail] = useInput(member?.email ?? '')
  const [role, setRole] = useState(member?.role)

  const canSave = name && email && role

  const save = () => {
    if (!canSave) return
    setLoading(true)
    onSave({
      id: isNew ? undefined : member.id,
      name,
      email,
      role,
    }).finally(() => setLoading(false))
  }

  return (
    <Modal
      title={isNew ? 'Add new member' : `Edit ${member.name}`}
      open
      confirmLoading={loading}
      okText={isNew ? 'Add' : 'Save'}
      okButtonProps={{ disabled: !canSave }}
      styles={{ body: { padding: '24px 0' } }}
      onOk={save}
      onCancel={onCancel}
    >
      <Flex vertical gap={12}>
        <InputLabelContainer>
          <label>Name</label>
          <Input value={name} onChange={setName} placeholder="Name" />
        </InputLabelContainer>

        <InputLabelContainer>
          <label>Email</label>
          <Input value={email} onChange={setEmail} placeholder="Email" />
        </InputLabelContainer>

        <InputLabelContainer>
          <label>Role</label>
          <Select
            value={role}
            onChange={setRole}
            placeholder="Role"
            showSearch
            options={Object.values(AssociationMemberRole).map(role => ({
              label: humanize(role),
              value: role,
            }))}
          />
        </InputLabelContainer>
      </Flex>
    </Modal>
  )
}

export default CreateEditAssociationMemberModal
