import React from 'react'
import { Breadcrumb } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { useMeeting } from '../../hooks/meeting/useMeetings'
import { Page, PageContent, PageHeader } from '../templates/page'
import { MeetingDetailsSubRoutes } from '../../router'
import RouteAwareTabs from '../organism/route-aware-tabs'

const MeetingDetailsPage = () => {
  const { id } = useParams()
  const { meeting } = useMeeting(id!!)

  return (
    <Page>
      <PageHeader>
        <Breadcrumb
          items={[
            { title: <Link to="/meetings">Meetings</Link> },
            { title: meeting?.name },
          ]}
        />
      </PageHeader>
      <PageContent>
        <RouteAwareTabs
          basePath={`/meetings/${id}/`}
          tabs={MeetingDetailsSubRoutes.map(r => ({
            path: r.path as string,
            label: r.label,
          }))}
        />
      </PageContent>
    </Page>
  )
}

export default MeetingDetailsPage
