import styled from 'styled-components'
import Title from 'antd/lib/typography/Title'
import React, { HTMLAttributes, PropsWithChildren } from 'react'
import { Skeleton, Typography } from 'antd'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
`

type Props = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    title?: string
    subTitle?: string
    loading?: boolean
  }
>
const Panel: React.FC<Props> = ({
  title,
  subTitle,
  loading,
  children,
  ...rest
}) => (
  <Container {...rest}>
    {(title || subTitle) && (
      <div>
        {title && <Title level={3}>{title}</Title>}
        {subTitle && (
          <Typography.Text type="secondary">{subTitle}</Typography.Text>
        )}
      </div>
    )}
    {loading ? <Skeleton /> : children}
  </Container>
)
export default Panel
