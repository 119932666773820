import { useLocation, useNavigate } from 'react-router-dom'
import { useCallback, useEffect } from 'react'
import qs from 'qs'
import { omit } from 'lodash'

type UseQueryParam<T> = [
  value: T,
  setter: (valueUpdater: T | ((prevValue: T) => T) | undefined) => void,
]

export const useQueryParam = <T>(
  name: string,
  initialValue?: T,
  silent?: boolean,
): UseQueryParam<T> => {
  const { search, pathname } = useLocation()
  const goto = useNavigate()
  const valueFromUrl = qs.parse(search, { ignoreQueryPrefix: true })[name]
  const isBlank =
    valueFromUrl === undefined || valueFromUrl === null || valueFromUrl === ''

  const isFunction = (value: any): value is (prevValue: T) => T => {
    return typeof value === 'function'
  }

  const setQuery = useCallback(
    (valueUpdater: T | ((prevValue: T) => T) | undefined) => {
      const existingQueries = qs.parse(search, { ignoreQueryPrefix: true })
      const currentValue = existingQueries[name] as T
      const newValue = isFunction(valueUpdater)
        ? valueUpdater(currentValue)
        : valueUpdater

      const queryString = qs.stringify(
        newValue === '' || newValue === undefined
          ? omit(existingQueries, name)
          : { ...existingQueries, [name]: newValue },
        { skipNulls: true, addQueryPrefix: true },
      )

      const url = `${pathname}${queryString}`
      silent
        ? window.history.replaceState({ path: url }, '', url)
        : goto(url, { replace: true })
    },
    // eslint-disable-next-line
    [pathname, search],
  )

  useEffect(() => {
    if (isBlank && initialValue !== undefined) {
      setQuery(initialValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [(valueFromUrl ?? initialValue) as T, setQuery]
}
