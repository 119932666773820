import React, { useContext } from 'react'
import { Button as AntButton, Tooltip } from 'antd'
import { ButtonType } from 'antd/lib/button'
import { AppContext } from '../../context/application-context'
import { logEvent } from 'firebase/analytics'
import { TooltipPlacement } from 'antd/lib/tooltip'
import { Link } from 'react-router-dom'

type ButtonProps = {
  analyticsId: string
  label?: string | React.ReactNode
  onClick?: () => void
  disabled?: boolean
  loading?: boolean
  icon?: React.ReactNode
  href?: string
  type?: ButtonType
  noBackground?: boolean
  darkLabel?: boolean
  className?: string
  tooltip?: React.ReactNode
  tooltipPlacement?: TooltipPlacement
}

const Button: React.FC<ButtonProps> = ({
  analyticsId,
  label,
  onClick,
  disabled,
  loading,
  type,
  icon,
  href,
  noBackground,
  darkLabel,
  className,
  tooltip,
  tooltipPlacement,
}) => {
  const { analytics } = useContext(AppContext)
  return (
    <Tooltip placement={tooltipPlacement} title={tooltip}>
      <AntButton
        type={type ?? 'primary'}
        className={className}
        shape="default"
        onClick={event => {
          if (analytics) {
            logEvent(analytics, 'button_click', {
              button_id: analyticsId.replace(/ /g, '_').toLowerCase(),
              href: window.location.href,
            })
          }
          if (onClick) {
            event.preventDefault()
            onClick()
          }
        }}
        htmlType={onClick ? 'button' : 'submit'}
        loading={loading}
        disabled={disabled}
        icon={icon}
        block
        style={{
          height: 'fit-content',
          maxHeight: '38px',
          ...(noBackground ? { background: 'None' } : {}),
          ...(darkLabel ? { color: '#000' } : {}),
        }}
      >
        {href ? <Link to={href}>{label}</Link> : label}
      </AntButton>
    </Tooltip>
  )
}

export default Button
