import React, { useContext, useState } from 'react'
import OnboardingContainer from '../templates/onboarding'
import { useCreateAssociation } from '../../hooks/association/useAssociation'
import { Input, Select, Space } from 'antd'
import { InputLabel, InputLabelContainer } from '../atoms/input'
import Button from '../atoms/button'
import { useBoolean } from '../../hooks/useBoolean'
import Spacer from '../atoms/spacer'
import XhrPending from '../atoms/xhr-pending'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { OrganizationContext } from '../../context/organization-context'
import { humanize } from '../../utils/utils'
import { AssociationType } from '../../types/graphql-types'

type Props = {
  onSuccess: () => Promise<void>
}

const AssociationCreatePage: React.FC<Props> = ({ onSuccess }) => {
  useDocumentTitle('Create your association')
  const { membership } = useContext(OrganizationContext)
  const [name, setName] = useState<string>(membership?.organization?.name ?? '')
  const [type, setType] = useState<AssociationType>()
  const [xhrPending, toggleXhrPending] = useBoolean()
  const createAssociation = useCreateAssociation()

  const canCreate = !!name && !!type

  const create = () => {
    if (canCreate) {
      toggleXhrPending()
      createAssociation({ name, type })
        .then(onSuccess)
        .finally(toggleXhrPending)
    }
  }

  return (
    <XhrPending pending={xhrPending}>
      <OnboardingContainer
        title="Create Your First Association"
        image="/myrna-create-association.webp"
      >
        <Spacer size="large" />
        <Space direction="vertical" size="large" style={{ width: '70%' }}>
          <InputLabelContainer>
            <InputLabel>Name</InputLabel>
            <Input
              placeholder="What's the association's name?"
              value={name}
              onChange={event => setName(event.target.value)}
              onPressEnter={create}
              allowClear
            />
          </InputLabelContainer>
          <InputLabelContainer>
            <InputLabel>Type</InputLabel>
            <Select
              placeholder="Select the association's type"
              value={type}
              options={Object.values(AssociationType).map(type => ({
                label: humanize(type),
                value: type,
              }))}
              onChange={setType}
              allowClear
            />
          </InputLabelContainer>
          <Button
            analyticsId="create_association"
            label="submit"
            onClick={create}
            disabled={!canCreate}
          />
        </Space>
      </OnboardingContainer>
    </XhrPending>
  )
}

export default AssociationCreatePage
