import { Drawer } from 'antd'
import React, { useEffect, useState } from 'react'

type Props = {
  url: string
  title?: string
  onClose: () => void
  open: boolean // Keep track of drawer's open state
}

const TallyFormDrawer: React.FC<Props> = ({ url, title, onClose, open }) => {
  const [iframeSrc, setIframeSrc] = useState<string | null>(null)

  // Load Tally embed script once and cache it
  useEffect(() => {
    const widgetScriptSrc = 'https://tally.so/widgets/embed.js'

    const loadTally = () => {
      // @ts-ignore
      if (typeof window.Tally !== 'undefined') {
        // @ts-ignore
        window.Tally.loadEmbeds()
      } else {
        document
          .querySelectorAll('iframe[data-tally-src]:not([src])')
          .forEach(iframe => {
            iframe.setAttribute(
              'src',
              iframe.getAttribute('data-tally-src') || '',
            )
          })
      }
    }

    // Check if script already exists in the document
    const scriptExists = document.querySelector(
      `script[src="${widgetScriptSrc}"]`,
    )

    if (!scriptExists) {
      const script = document.createElement('script')
      script.src = widgetScriptSrc
      script.async = true
      script.onload = loadTally
      document.body.appendChild(script)
    } else {
      // Script already exists, just load Tally
      loadTally()
    }
  }, [])

  // When the drawer is opened, reset the iframe and loading state
  useEffect(() => {
    if (open) {
      // Set iframe source again when the drawer is opened
      setIframeSrc(url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Drawer
      title={title}
      open={open}
      onClose={onClose}
      size="large"
      styles={{ body: { padding: 0 } }}
    >
      <iframe
        title={title}
        src={iframeSrc || undefined} // Ensure src is cleared when iframeSrc is null
        data-tally-src={url}
        width="100%"
        height="100%"
        frameBorder="0"
        marginHeight={0}
        marginWidth={0}
      />
    </Drawer>
  )
}

export default TallyFormDrawer
