import React, { useContext, useEffect } from 'react'
import { useBillingSession } from '../../hooks/organization/useOrganization'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { OrganizationContext } from '../../context/organization-context'
import Loading from './loading'

type Props = {
  onSuccess: () => Promise<void>
}

const OrganizationPaywallPage: React.FC<Props> = ({ onSuccess }) => {
  useDocumentTitle('Choose a plan')
  const { membership } = useContext(OrganizationContext)
  const { loading, data: session } = useBillingSession(window.location.href)
  const plan = membership?.organization?.plan

  useEffect(() => {
    if (loading) return
    if (plan) {
      onSuccess()
      return
    }
    if (!!session) window.location.href = session

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, session, plan])

  return <Loading />
}

export default OrganizationPaywallPage
