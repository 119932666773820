// eslint-disable-next-line import/no-anonymous-default-export
export default {
  not_whitelisted_welcome: "You're on Our Waiting List!",
  not_whitelisted_notify:
    "Excited to see you here! We'll notify you when your spot opens up.",
  invitations_invalid_expired:
    'The link you followed appears to be invalid or expired.',
  invitations_use_correct:
    "Please ensure you're using the correct invitation link, or request a new invitation from the sender to proceed.",
  invitations_logout: 'Kindly log out to continue with the invited profile.',
  invitations_signin: 'Sign in to accept the invitation',

  meeting_status_SCHEDULED: 'Scheduled',
  meeting_status_IN_PROGRESS: 'In Progress',
  meeting_status_ENDED: 'Ended',
  meeting_status_TRANSCRIBING: 'Transcribing',
  meeting_status_TRANSCRIPTION_FAILED: 'Transcription Failed',
  meeting_status_SUMMARIZING: 'Summarizing',
  meeting_status_SUMMARIZATION_FAILED: 'Summarization Failed',
  meeting_status_SUMMARIZED: 'Summarized',
  meeting_status_COMPLETED: 'Completed',
  meeting_status_FAILED_TO_JOIN_400: 'Invalid meeting url',
  meeting_status_FAILED_TO_JOIN_401: 'Bot declined to join',
  meeting_status_FAILED_TO_JOIN_408: 'Bot timed out',
  meeting_status_FAILED_TO_JOIN_500: 'Bot error',
  meeting_status_CANCELLED: 'Cancelled',

  sensitive_topic_ACQUISITIONS: 'Acquisitions',
  sensitive_topic_CLIENT_INFORMATION: 'Client Information',
  sensitive_topic_FINANCIAL_DETAILS: 'Financial Details',
  sensitive_topic_LEGAL_MATTERS: 'Legal Matters',
  sensitive_topic_PERSONNEL_INFORMATION: 'Personnel Information',
  sensitive_topic_SECURITY_DETAILS: 'Security Details',
  sensitive_topic_STRATEGIC_PLANS: 'Strategic Plans',
  sensitive_topic_TRADE_SECRETS: 'Trade Secrets',
}
