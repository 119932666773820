import { Summary } from '../../types/minutes-summary'
import { List } from 'antd'
import React, { useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import './meeting-intelligence-summary.css'

type Props = {
  summaryUrl: string | undefined
  onClickContent: (time: string) => void
}

const MeetingIntelligenceSummary: React.FC<Props> = ({
  summaryUrl,
  onClickContent,
}) => {
  const [loadingSummary, setLoadingSummary] = useState(false)
  const [summary, setSummary] = useState<Summary>()

  useEffect(() => {
    if (!summaryUrl) return

    setLoadingSummary(true)
    fetch(summaryUrl)
      .then(response => response.json())
      .then(setSummary)
      .finally(() => setLoadingSummary(false))
  }, [summaryUrl])

  return (
    <List
      loading={loadingSummary}
      itemLayout="vertical"
      split={false}
      bordered={false}
      dataSource={summary}
      renderItem={item => (
        <List.Item
          style={{
            paddingTop: 0,
            paddingBottom: 12,
          }}
        >
          <Markdown>{item.section}</Markdown>
          {item.content.length !== 0 && (
            <List
              itemLayout="vertical"
              split={false}
              bordered={false}
              dataSource={item.content}
              renderItem={content => (
                <List.Item
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => onClickContent(content.timestamp)}
                >
                  <Markdown>{content.text}</Markdown>
                </List.Item>
              )}
            />
          )}
        </List.Item>
      )}
    />
  )
}

export default MeetingIntelligenceSummary
