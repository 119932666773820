import { Col, Flex, Row } from 'antd'
import Title from 'antd/lib/typography/Title'
import React, { PropsWithChildren } from 'react'
import FullScreenLayout from './full-screen-layout'
import styled from 'styled-components'
import Spacer from '../atoms/spacer'

type Props = PropsWithChildren & {
  title: string
  image?: string
}

const FullScreenRow = styled(Row)`
  height: 100vh;
`
const ContentContainer = styled(Col)`
  height: 100%;
  width: 100%;
  padding: 48px;
  align-content: center;
`
const ImgContainer = styled.img`
  width: 26vw;
  display: block;
  margin: 0 auto;
`
const OnboardingContainer: React.FC<Props> = ({
  image = '/myrna.webp',
  title,
  children,
}) => (
  <FullScreenLayout>
    <FullScreenRow align="middle" justify="center">
      <ContentContainer span={12} style={{ background: '#fafafa' }}>
        <ImgContainer src={image} alt="onboarding-animation" />
      </ContentContainer>
      <ContentContainer span={12}>
        <Flex gap={6} align="middle">
          <img
            src="/logo.png"
            alt="myrna-logo"
            style={{ width: '6%', height: '6%' }}
          />
          <Title level={2}>{title}</Title>
        </Flex>
        <Spacer />
        {children}
      </ContentContainer>
    </FullScreenRow>
  </FullScreenLayout>
)

export default OnboardingContainer
