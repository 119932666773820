import VideoPlayer from '../organism/video-player'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Player from 'video.js/dist/types/player'
import { Button, Card, Col, Empty, Flex, Input, Result, Row, Tabs } from 'antd'
import Transcript from '../organism/transcript'
import Spacer, { spacerSizeMapping } from '../atoms/spacer'
import { useParams } from 'react-router-dom'
import { useMeeting } from '../../hooks/meeting/useMeetings'
import LoadingPage from './loading'
import { SearchOutlined } from '@ant-design/icons'
import useInput from '../../hooks/useInput'
import MeetingIntelligenceSummary from '../organism/meeting-intelligence-summary'
import MeetingIntelligenceAssistant from '../organism/meeting-intelligence-assistant'

const MeetingDetailsIntelligence = () => {
  const { id } = useParams()
  const minutesSummaryContainerRef = useRef<HTMLDivElement>(null)
  const { loading, meeting } = useMeeting(id!!)
  const [currentTime, setCurrentTime] = useState(0)
  const [videoPlayer, setVideoPlayer] = useState<Player | null>(null)
  const transcriptCardRef = useRef<HTMLDivElement>(null)
  const [transcriptBodyHeight, setTranscriptBodyHeight] = useState(0)
  const [transcriptSearchQuery, setTranscriptSearchQuery] = useInput('')
  const [intelligenceSectionTab, setIntelligenceSectionTab] =
    useState('summary')

  const handleTimeUpdate = useCallback((time: number) => {
    setCurrentTime(time)
  }, [])

  const handleSeek = useCallback(
    (time: number) => {
      if (videoPlayer) {
        videoPlayer.currentTime(time)
      }
    },
    [videoPlayer],
  )

  const setPlayerRef = useCallback((player: Player) => {
    setVideoPlayer(player)
  }, [])

  const updateTranscriptWidth = () => {
    const videoPlayerHeight = videoPlayer?.currentHeight()
    if (videoPlayerHeight) {
      const parentHeight =
        transcriptCardRef?.current?.parentElement?.clientHeight || 0
      const spacerHeight = +spacerSizeMapping.medium.replace('px', '')
      const transcriptCardHeight =
        parentHeight - videoPlayerHeight - spacerHeight
      transcriptCardRef.current?.style.setProperty(
        'height',
        `${transcriptCardHeight}px`,
      )
      setTranscriptBodyHeight(transcriptCardHeight * 0.83)
    }
  }

  useEffect(() => {
    try {
      updateTranscriptWidth()
      window.addEventListener('resize', updateTranscriptWidth)
      return () => {
        window.removeEventListener('resize', updateTranscriptWidth)
      }
    } catch (e) {
      console.error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoPlayer])

  if (loading) return <LoadingPage />

  if (!meeting)
    return (
      <Result
        status="404"
        title="Something is wrong!"
        subTitle="Sorry, the meeting you are looking for does not exist."
        extra={
          <Button type="primary" href="/">
            Back Home
          </Button>
        }
      />
    )

  if (!meeting.recordingUrl || !meeting.transcriptUrl)
    return <Empty description="No recording available" />

  const intelligenceSectionTabs = [
    {
      label: 'Summary',
      key: 'summary',
      component: () => (
        <MeetingIntelligenceSummary
          summaryUrl={meeting?.summaryUrl ?? undefined}
          onClickContent={timestamp => {
            const [hours, minutes, seconds] = timestamp.split(':')
            const totalSeconds = +hours * 3600 + +minutes * 60 + +seconds
            handleSeek(totalSeconds)
          }}
        />
      ),
    },
    {
      label: 'Assistant',
      key: 'assistant',
      component: () => <MeetingIntelligenceAssistant meetingId={id!!} />,
    },
  ]

  return (
    <Row gutter={12} style={{ height: '100%' }}>
      <Col span={8} style={{ height: '100%', gap: 12 }}>
        <VideoPlayer
          url={meeting.recordingUrl}
          onTimeUpdate={handleTimeUpdate}
          setPlayerRef={setPlayerRef}
        />
        <Spacer size="medium" />
        <Card
          ref={transcriptCardRef}
          title="Transcript"
          size="small"
          extra={
            <Flex justify="flex-end">
              <Input
                size="small"
                variant="filled"
                prefix={<SearchOutlined />}
                style={{ width: '70%' }}
                allowClear
                placeholder="Search transcript"
                value={transcriptSearchQuery}
                onChange={setTranscriptSearchQuery}
              />
            </Flex>
          }
          style={{ height: '100%' }}
          styles={{ body: { height: transcriptBodyHeight, overflow: 'auto' } }}
        >
          <Transcript
            transcriptUrl={meeting.transcriptUrl}
            transcriptFormat={meeting.transcriptFormat!!}
            currentTime={currentTime}
            onWordClick={handleSeek}
            searchQuery={transcriptSearchQuery}
          />
        </Card>
      </Col>
      <Col span={16} style={{ height: '100%' }}>
        <Card
          title={
            <Tabs
              type="line"
              size="small"
              activeKey={intelligenceSectionTab}
              items={intelligenceSectionTabs.map(tab => ({
                key: tab.key,
                label: tab.label,
              }))}
              onChange={setIntelligenceSectionTab}
            />
          }
          size="small"
          ref={minutesSummaryContainerRef}
          style={{ height: '100%' }}
          styles={{
            body: {
              height:
                (minutesSummaryContainerRef.current?.clientHeight || 1) * 0.9,
              flex: 1, overflowY: 'scroll'
            },
          }}
        >
          {intelligenceSectionTabs
            .find(tab => tab.key === intelligenceSectionTab)!
            .component()}
        </Card>
      </Col>
    </Row>
  )
}

export default MeetingDetailsIntelligence
