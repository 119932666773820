import { createBrowserRouter, RouteObject } from 'react-router-dom'
import React from 'react'
import OrganizationIdentity from './components/pages/organization-identity'
import OrganizationMembers from './components/pages/organization-members'
import OrganizationMemberDetailsPage from './components/pages/organization-member-details'
import OrganizationSettings from './components/pages/organization-settings'
import LoginPage from './components/pages/login'
import { AlertManagerProvider } from './context/alert-manager'
import { OrganizationContextProvider } from './context/organization-context'
import OrganizationAssociations from './components/pages/organization-associations'
import { AssociationContextProvider } from './context/association-context'
import AssociationSettings from './components/pages/association-settings'
import AssociationOverview from './components/pages/association-overview'
import {
  AssociationAwareLayout,
  DefaultLayout,
} from './components/templates/layout'
import AssociationMembers from './components/pages/association-members'
import MeetingsListPage from './components/pages/meeting-list'
import MeetingDetailsPage from './components/pages/meeting-details'
import MeetingDetailsIntelligence from './components/pages/meeting-details-intelligence'
import MeetingDetailsOverview from './components/pages/meeting-details-overview'
import MeetingDetailsDocuments from './components/pages/meeting-details-documents'
import InvitePage from './components/pages/invite'
import NotWhitelistedPage from './components/pages/not-whitelisted'

type RouteDefinition = RouteObject & {
  label: string
}

export const MeetingDetailsSubRoutes: RouteDefinition[] = [
  {
    path: 'overview',
    label: 'Overview',
    element: <MeetingDetailsOverview />,
  },
  {
    path: 'documents',
    label: 'Documents',
    element: <MeetingDetailsDocuments />,
  },
  {
    path: 'intelligence',
    label: 'Intelligence',
    element: <MeetingDetailsIntelligence />,
  },
]

export const AssociationSettingsSubRoutes: RouteDefinition[] = [
  {
    path: 'overview',
    label: 'Overview',
    element: <AssociationOverview />,
  },
  {
    path: 'members',
    label: 'Members',
    element: <AssociationMembers />,
  },
]

export const OrganizationSettingsSubRoutes: RouteDefinition[] = [
  {
    path: 'overview',
    label: 'Overview',
    element: <OrganizationIdentity />,
  },
  {
    path: 'members',
    label: 'Members',
    children: [
      { path: '', element: <OrganizationMembers /> },
      { path: ':id', element: <OrganizationMemberDetailsPage /> },
    ],
  },
  {
    path: 'associations',
    label: 'Associations',
    element: <OrganizationAssociations />,
  },
  // TODO: Enable once billing is implemented
  // {
  //   path: 'billing',
  //   label: 'Billing',
  //   element: <OrganizationBilling />,
  // },
]
export const InviteRoute: RouteObject = {
  path: '/invitations',
  children: [
    {
      path: '/invitations/:id',
      element: <InvitePage />,
    },
  ],
}

export const AuthRouter = createBrowserRouter([
  InviteRoute,
  {
    path: '*',
    element: (
      <AlertManagerProvider overlay={true}>
        <LoginPage />
      </AlertManagerProvider>
    ),
  },
])

export const NotWhitelistedRouter = createBrowserRouter([
  {
    path: '*',
    element: (
      <AlertManagerProvider overlay={true}>
        <NotWhitelistedPage />
      </AlertManagerProvider>
    ),
  },
])

export const MainRouter = createBrowserRouter([
  InviteRoute,
  {
    path: '/organization',
    element: (
      <OrganizationContextProvider>
        <DefaultLayout />
      </OrganizationContextProvider>
    ),
    children: [
      {
        path: '',
        element: <OrganizationSettings />,
        children: OrganizationSettingsSubRoutes,
      },
    ],
  },
  {
    path: '/',
    element: (
      <OrganizationContextProvider>
        <AssociationContextProvider>
          <AssociationAwareLayout />
        </AssociationContextProvider>
      </OrganizationContextProvider>
    ),
    children: [
      {
        path: '/meetings',
        children: [
          {
            path: '',
            element: <MeetingsListPage />,
          },
          {
            path: ':id',
            element: <MeetingDetailsPage />,
            children: MeetingDetailsSubRoutes,
          },
        ],
      },
      {
        path: '/settings',
        element: <AssociationSettings />,
        children: AssociationSettingsSubRoutes,
      },
    ],
  },
])
