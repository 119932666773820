import React, { useContext, useEffect, useState } from 'react'
import { Page, PageContent, PageHeader } from '../templates/page'
import Panel from '../organism/panel'
import { InputLabel, InputLabelContainer } from '../atoms/input'
import { Col, Input, Row, Select, Space, Transfer } from 'antd'
import { useParams } from 'react-router-dom'
import Button from '../atoms/button'
import {
  useEditMember,
  useMemberById,
} from '../../hooks/organization/useOrganization'
import {
  TwoColumnsContainer,
  TwoColumnsLeftContainer,
  TwoColumnsRightContainer,
} from '../molecules/two-columns'
import {
  OrganizationMemberRole,
  OrganizationMemberStatus,
} from '../../types/graphql-types'
import { OrganizationContext } from '../../context/organization-context'
import { useBoolean } from '../../hooks/useBoolean'
import { extensionFrom, omitUndefined } from '../../utils/utils'
import _, { isEqual } from 'lodash'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import useFileUpload from '../../hooks/useFileUpload'
import ImageUpload from '../molecules/img-upload'
import useInput from '../../hooks/useInput'
import {
  useAssociations,
  useGrantAssociationAccess,
  useOrganizationMemberAssociations,
  useRevokeAssociationAccess,
} from '../../hooks/association/useAssociation'
import Spacer from '../atoms/spacer'
import XhrPending from '../atoms/xhr-pending'

const roleOptions = Object.values(OrganizationMemberRole).map(role => {
  return { value: role, label: role }
})
const statusOptions = Object.values(OrganizationMemberStatus).map(role => {
  return { value: role, label: role }
})

const OrganizationMemberDetailsPage = () => {
  const { id } = useParams<{ id: string }>()
  const { membership } = useContext(OrganizationContext)
  const { associations } = useAssociations()
  const { loading, data, refetch } = useMemberById(
    membership!.organization.id,
    id!,
  )
  const [xhrPending, toggleXhrPending] = useBoolean()
  const [name, setName] = useInput<string>()
  const [avatar, setAvatar] = useState<string>()
  const [avatarFile, setAvatarFile] = useState<File>()
  const [role, setRole] = useState<OrganizationMemberRole>()
  const [status, setStatus] = useState<OrganizationMemberStatus>()
  const edit = useEditMember()
  const { upload } = useFileUpload()
  useDocumentTitle(`Settings - Members - ${data?.name}`, {
    skip: !data,
  })
  const { associations: authorizedAssociations } =
    useOrganizationMemberAssociations(id!)
  const [changingAssociationIds, setChangingAssociationIds] = useState<
    string[]
  >([])
  const grantAccess = useGrantAssociationAccess()
  const revokeAccess = useRevokeAssociationAccess()
  const [savingAssociationAccess, toggleSavingAssociationAccess] = useBoolean()

  useEffect(() => {
    if (authorizedAssociations.length) {
      setChangingAssociationIds(authorizedAssociations.map(a => a.id))
    }
  }, [authorizedAssociations])

  useEffect(() => {
    console.log('changingAssociationIds', changingAssociationIds)
    const allAssociationIds = associations.map(a => a.id)
    if (!allAssociationIds.length) return

    const authorizedAssociationIds = authorizedAssociations.map(a => a.id)
    if (!_.difference(authorizedAssociationIds, changingAssociationIds)) return

    toggleSavingAssociationAccess()
    Promise.all([
      //   associations to grant access
      ..._.difference(changingAssociationIds, allAssociationIds).map(
        associationId => grantAccess(associationId, id!),
      ),
      // associations to revoke access
      ..._.difference(allAssociationIds, changingAssociationIds).map(
        associationId => revokeAccess(associationId, id!),
      ),
    ]).finally(toggleSavingAssociationAccess)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changingAssociationIds])

  const isAdmin =
    membership!.role === OrganizationMemberRole.Admin ||
    membership!.role === OrganizationMemberRole.Owner

  const canEdit =
    membership!.role !== OrganizationMemberRole.User &&
    (isAdmin || id === membership!.id.identifier)

  const updatable = {
    ...data,
    ...omitUndefined({
      name,
      avatar,
      role,
      status,
    }),
  }
  const hasChanged = !isEqual(updatable, data) || avatarFile
  const canSave = !xhrPending && hasChanged
  const save = () => {
    if (canSave) {
      const uploadNewLogoIfAny = () =>
        avatarFile
          ? upload(
              avatarFile,
              `organizations/${membership?.id.orgId}/members/${membership?.id?.identifier}/avatar-${new Date().toISOString()}.${extensionFrom(avatarFile)}`,
            ).then(({ url }) => url)
          : Promise.resolve(membership?.avatar)
      toggleXhrPending()
      uploadNewLogoIfAny().then(avatarUrl =>
        edit({
          orgId: membership!.organization.id,
          identifier: id!,
          name,
          avatar: avatarUrl,
          role,
          status,
        })
          .then(() => {
            if (avatarFile) {
              setAvatar(undefined)
              setAvatarFile(undefined)
            }
            refetch()
          })
          .finally(toggleXhrPending),
      )
    }
  }

  return (
    <Page xhrPending={xhrPending || loading}>
      <PageHeader>
        <div />
        <div>
          <Button
            analyticsId="update_organization_member"
            label="save"
            onClick={save}
            disabled={!canSave}
          />
        </div>
      </PageHeader>
      <PageContent>
        <TwoColumnsContainer>
          <TwoColumnsLeftContainer>
            <Panel>
              <Row>
                <Col flex="auto">
                  <ImageUpload
                    url={avatar ?? data?.avatar ?? ''}
                    label="Avatar"
                    name="organization-member-avatar"
                    onUrlChange={setAvatar}
                    onFileChange={setAvatarFile}
                    disabled={!canEdit || !isAdmin}
                  />
                </Col>
                <Col span={20}>
                  <Space
                    direction="vertical"
                    size="middle"
                    style={{ display: 'flex' }}
                  >
                    <InputLabelContainer>
                      <InputLabel>Name</InputLabel>
                      <Input
                        value={name ?? data?.name ?? ''}
                        placeholder="David Chen"
                        readOnly={!canEdit || !isAdmin}
                        onChange={setName}
                        allowClear
                        required
                      />
                    </InputLabelContainer>
                  </Space>
                </Col>
              </Row>
            </Panel>
            <Spacer size="large" />
            <Panel title="Associations access">
              <XhrPending pending={savingAssociationAccess}>
                <Transfer
                  style={{
                    flex: 1,
                    alignContent: 'center',
                    justifyContent: 'center',
                  }}
                  listStyle={{ width: '47%', height: '220px' }}
                  dataSource={associations.map(a => ({
                    key: a.id,
                    title: a.name,
                  }))}
                  targetKeys={changingAssociationIds}
                  onChange={keys => setChangingAssociationIds(keys as string[])}
                  locale={{
                    searchPlaceholder: 'Search for associations',
                    itemUnit: '',
                    itemsUnit: '',
                  }}
                  titles={['Hidden', 'Authorized']}
                  render={item => item.title}
                  disabled={savingAssociationAccess}
                  oneWay
                />
              </XhrPending>
            </Panel>
          </TwoColumnsLeftContainer>
          <TwoColumnsRightContainer>
            <Panel>
              <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
              >
                <InputLabelContainer>
                  <InputLabel>Role</InputLabel>
                  <Select
                    value={role ?? data?.role}
                    dropdownStyle={
                      !canEdit || !isAdmin ? { display: 'none' } : undefined
                    }
                    options={roleOptions}
                    onChange={val => setRole(val as OrganizationMemberRole)}
                  />
                </InputLabelContainer>

                <InputLabelContainer>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={status ?? data?.status}
                    dropdownStyle={
                      !canEdit || !isAdmin ? { display: 'none' } : undefined
                    }
                    options={statusOptions}
                    onChange={val => setStatus(val as OrganizationMemberStatus)}
                  />
                </InputLabelContainer>
              </Space>
            </Panel>
          </TwoColumnsRightContainer>
        </TwoColumnsContainer>
      </PageContent>
    </Page>
  )
}

export default OrganizationMemberDetailsPage
