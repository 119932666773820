import { Button, Divider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import React from 'react'

type Props = {
  menu: React.ReactElement
  onClickAddParticipant?: () => void
}

const ParticipantSelectRender: React.FC<Props> = ({
  menu,
  onClickAddParticipant,
}) => (
  <>
    {menu}
    {onClickAddParticipant && (
      <>
        <Divider style={{ margin: '8px 0' }} />
        <div style={{ width: '100%', paddingBottom: 8 }}>
          <Button
            type="text"
            icon={<PlusOutlined />}
            onClick={onClickAddParticipant}
            style={{ width: '100%' }}
          >
            Add Participants
          </Button>
        </div>
      </>
    )}
  </>
)

export default ParticipantSelectRender
