import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  signInWithPopup,
  User as FirebaseUser,
} from 'firebase/auth'
import { AppContext } from './application-context'
import LoadingPage from '../components/pages/loading'
import useAccountRegistration from '../hooks/account/useAccount'
import { Account } from '../types/graphql-types'
import { noop } from 'lodash'

type SecurityContextProps = {
  account?: Account
  token?: string
  login: () => Promise<any>
  logout: () => void
}

export const SecurityContext = React.createContext<SecurityContextProps>({
  login: Promise.reject,
  logout: noop,
})

export const SecurityContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { firebase, google } = useContext(AppContext)
  const auth = getAuth(firebase)
  const [account, setAccount] = useState<Account>()
  const [token, setToken] = useState<string>()
  const [loading, setLoading] = useState(true)
  const { exchangeFirebaseToken } = useAccountRegistration()
  const [exchangingToken, setExchangingToken] = useState(false)

  useEffect(() => {
    getRedirectResult(auth)
      .then(result => {
        setExchangingToken(true)
        return result
          ? exchangeFirebaseToken(
              // GoogleAuthProvider.credentialFromResult(result)?.accessToken!!,
              GoogleAuthProvider.credentialFromResult(result)?.idToken!!,
            ).finally(() => setExchangingToken(false))
          : Promise.resolve(undefined)
      })
      .then(setAccount)

    auth.onIdTokenChanged((u: FirebaseUser | null) => {
      if (u) {
        u.getIdToken()
          .then(t => {
            setToken(t)
            return exchangingToken || (!!account && !!token)
              ? Promise.resolve()
              : exchangeFirebaseToken(t).then(setAccount)
          })
          .then(() => setLoading(false))
      } else setLoading(false)
    }, console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const login = () => signInWithPopup(auth, google!.auth)
  const logout = () => {
    auth.signOut().then(() => setAccount(undefined))
  }

  return (
    <SecurityContext.Provider value={{ account, token, login, logout }}>
      {loading ? <LoadingPage /> : children}
    </SecurityContext.Provider>
  )
}
