import React, { useContext } from 'react'
import { ConfigProvider, theme } from 'antd'
import { RouterProvider } from 'react-router-dom'
import { ThemeConfig } from 'antd/es/config-provider/context'
import {
  AuthRouter,
  InviteRoute,
  MainRouter,
  NotWhitelistedRouter,
} from './router'
import {
  SecurityContext,
  SecurityContextProvider,
} from './context/security-context'
import { AppContextProvider } from './context/application-context'
import client from './graphql/apollo-client'
import { ApolloProvider } from '@apollo/client'
import { AlertManagerProvider } from './context/alert-manager'
import initTranslations from './i18n'
import { pdfjs } from 'react-pdf'

initTranslations()

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

const Router = () => {
  const { account } = useContext(SecurityContext)
  const isInvite = window.location.pathname.startsWith(InviteRoute.path!!)

  if (!account) {
    return <RouterProvider router={AuthRouter} />
  }

  return (
    <RouterProvider
      router={
        account.whitelisted || isInvite ? MainRouter : NotWhitelistedRouter
      }
    />
  )
}

const App = () => (
  <AlertManagerProvider overlay={true}>
    <ConfigProvider
      theme={
        {
          algorithm: theme.defaultAlgorithm,
          token: {
            colorPrimary: '#151516',
            colorLink: '#1E1E1E',

            borderRadius: 8,
            colorBgLayout: '#FFF',
          },
          components: {
            Layout: {
              headerBg: '#fff',
              headerPadding: '0 24px',
            },
            Typography: {
              titleMarginTop: 0,
              titleMarginBottom: 0,
            },
            Menu: {
              itemSelectedBg: '#151516',
              itemSelectedColor: '#FFF',
            },
            Select: {
              optionSelectedBg: 'rgba(0, 0, 0, 0.04)',
              optionActiveColor: '#FFF',
            },
            Button: { primaryShadow: 'none' },
            Grid: {
              row: {
                width: '100%',
                margin: 0,
              },
              col: {
                padding: 0,
              },
            },
            Card: {
              headerHeight: 46,
            },
          },
        } as ThemeConfig
      }
    >
      <ApolloProvider client={client}>
        <AppContextProvider>
          <SecurityContextProvider>
            <Router />
          </SecurityContextProvider>
        </AppContextProvider>
      </ApolloProvider>
    </ConfigProvider>
  </AlertManagerProvider>
)

export default App
