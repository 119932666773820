import { Avatar, Col, Dropdown, Flex, Layout, Menu, MenuProps, Row } from 'antd'
import {
  BugOutlined,
  CalendarOutlined,
  CommentOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons'
import React, { PropsWithChildren, useContext, useEffect, useMemo } from 'react'
import { SecurityContext } from '../../context/security-context'
import { AssociationContext } from '../../context/association-context'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import AssociationSelect from '../organism/association-select'
import { useBoolean } from '../../hooks/useBoolean'
import TallyFormDrawer from '../organism/tally-form-drawer'

const Logo = () => (
  <Link to="/">
    <img src="/logo.png" alt="logo" style={{ width: '100%' }} />
  </Link>
)

const HeaderContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <Layout.Header
    style={{
      position: 'sticky',
      gap: 12,
      top: 0,
      zIndex: 1,
      width: '100%',
      borderBottom: '1px solid rgba(5, 5, 5, 0.06)',
    }}
  >
    {children}
  </Layout.Header>
)

const UserAvatar: React.FC = () => {
  const { account, logout } = useContext(SecurityContext)

  return (
    <Dropdown
      placement="bottomRight"
      menu={{
        items: [
          {
            key: 'organization_settings',
            label: <Link to="/organization">Organization settings</Link>,
          },
          { type: 'divider' },
          {
            key: 'sign_out',
            label: 'Sign Out',
            icon: <LogoutOutlined />,
            onClick: logout,
          },
        ],
      }}
    >
      <Avatar size="large" src={account?.avatar} icon={<UserOutlined />} />
    </Dropdown>
  )
}

const associationAwareSideMenuItems: MenuProps['items'] = [
  { icon: CalendarOutlined, label: 'Meetings', path: '/meetings' },
  { icon: SettingOutlined, label: 'Settings', path: '/settings' },
].map(({ label, icon, path }) => ({
  key: path,
  icon: React.createElement(icon),
  label,
}))

export const AssociationAwareLayout = () => {
  const { account } = useContext(SecurityContext)
  const { associations, association, setAssociationId } =
    useContext(AssociationContext)
  const location = useLocation()
  const goto = useNavigate()
  const [feedbackDrawerOpened, toggleFeedbackDrawer] = useBoolean(false)
  const [reportBuckDrawerOpened, toggleReportBugDrawer] = useBoolean(false)

  useEffect(() => {
    // Redirect to the first menu item if the current path is the root
    if (location.pathname === '/')
      goto(associationAwareSideMenuItems[0]!.key as string)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const activeMenuKey = useMemo(
    () =>
      associationAwareSideMenuItems
        .filter(item => location.pathname.startsWith(item!.key as string))
        .map(item => item!.key) ?? [],
    [location.pathname],
  )

  return (
    <Layout style={{ height: '100%' }}>
      <HeaderContainer>
        <Row align="middle" justify="space-between">
          <Col span={20}>
            <Row align="middle" gutter={24}>
              <Col style={{ height: 58, width: 58, padding: 12 }}>
                <Logo />
              </Col>
              <Col span={10}>
                <AssociationSelect
                  activeAssociationId={association?.id}
                  associations={associations}
                  onChange={setAssociationId}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <UserAvatar />
          </Col>
        </Row>
      </HeaderContainer>
      <Layout hasSider style={{ height: '100%' }}>
        <Layout.Sider theme={'light'} collapsed style={{ height: '100%' }}>
          <Flex vertical justify="space-between" style={{ minHeight: '100%' }}>
            <Menu
              items={associationAwareSideMenuItems}
              selectedKeys={activeMenuKey as string[]}
              onClick={({ key }) => goto(key)}
              style={{ flex: 0.98, padding: '24px 12px' }}
            />
            <Menu
              mode="vertical"
              triggerSubMenuAction="hover"
              selectable={false}
              items={[
                {
                  key: 'help',
                  icon: <QuestionCircleOutlined />,
                  children: [
                    {
                      key: 'feedback',
                      label: 'Share Feedback',
                      icon: <CommentOutlined />,
                      onClick: toggleFeedbackDrawer,
                    },
                    {
                      key: 'report_issue',
                      label: 'Report an issue',
                      icon: <BugOutlined />,
                      onClick: toggleReportBugDrawer,
                    },
                  ],
                },
              ]}
              style={{
                flex: 0.02,
                padding: '24px 12px',
                alignContent: 'center',
              }}
            />
          </Flex>
        </Layout.Sider>
        <Layout.Content style={{ minHeight: '100%', overflow: 'auto' }}>
          <Outlet />
        </Layout.Content>
      </Layout>

      <TallyFormDrawer
        title="Help Us Improve!"
        url={`https://tally.so/r/3yvWad?hideTitle=1&email=${account?.email}`}
        open={feedbackDrawerOpened}
        onClose={toggleFeedbackDrawer}
      />
      <TallyFormDrawer
        title="Report an issue"
        url={`https://tally.so/r/nPXjdB?hideTitle=1&email=${account?.email}&url=${location.pathname}`}
        open={reportBuckDrawerOpened}
        onClose={toggleReportBugDrawer}
      />
    </Layout>
  )
}

export const DefaultLayout = () => (
  <Layout style={{ minHeight: '100vh' }}>
    <HeaderContainer>
      <Row align="middle" justify="space-between">
        <Col style={{ height: 58, width: 58, padding: 12 }}>
          <Logo />
        </Col>
        <Col>
          <UserAvatar />
        </Col>
      </Row>
    </HeaderContainer>
    <Layout.Content style={{ minHeight: '100%', overflow: 'auto' }}>
      <Outlet />
    </Layout.Content>
  </Layout>
)
