import { useContext } from 'react'
import { SecurityContext } from '../../context/security-context'
import OnboardingContainer from '../templates/onboarding'
import Button from '../atoms/button'
import { Space, Typography } from 'antd'
import { t } from 'i18next'

const NotWhitelistedPage = () => {
  const { account, logout } = useContext(SecurityContext)

  return (
    <OnboardingContainer
      image="/myrna-waiting-list.png"
      title={t('not_whitelisted_welcome')}
    >
      <Space direction="vertical" size="large">
        <Typography.Text>
          Excited to see you here! Complete the form to fast-track your spot on
          the waiting list.
        </Typography.Text>
        <Button
          analyticsId="complete_form_not_whitelisted"
          href={`https://tally.so/r/mVYjdl?email=${account?.email!!}`}
          label="Fast-Track My Spot"
          type="primary"
        />
        <Button
          analyticsId="logout_not_whitelisted"
          onClick={logout}
          label="logout"
          type="default"
        />
      </Space>
    </OnboardingContainer>
  )
}

export default NotWhitelistedPage
