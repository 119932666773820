import { gql } from '@apollo/client'
import useMutation from '../useMutation'
import {
  GptMessage,
  GptMessages,
  NewThreadInput,
  ThreadMessagesRequestFilterInput,
} from '../../types/graphql-types'
import useQuery, { useLazyQuery } from '../useQuery'
import { noop } from 'lodash'

const createThreadMutation = gql`
  mutation CreateThread($newThread: NewThreadInput!) {
    createThread(newThread: $newThread) {
      id
      role
      content {
        text {
          value
          annotations {
            __typename
            ... on GptFileCitation {
              text
              start_index
              end_index
              #              file_citation {
              #              }
            }
            ... on GptFilePath {
              text
              start_index
              end_index
              #              file_path {
              #              }
            }
          }
        }
      }
      created_at
    }
  }
`

export const useCreateThread = () => {
  const [createThread] = useMutation(createThreadMutation)

  return (newThread: NewThreadInput): Promise<GptMessage[]> =>
    createThread({ variables: { newThread } }).then(
      response => response.data.createThread,
    )
}

const messagesQuery = gql`
  query Messages($filter: ThreadMessagesRequestFilterInput!) {
    messages(filter: $filter) {
      data {
        id
        role
        content {
          text {
            value
            annotations {
              __typename
              ... on GptFileCitation {
                text
                start_index
                end_index
                file_citation {
                  filename
                }
              }
              ... on GptFilePath {
                text
                start_index
                end_index
                file_path {
                  filename
                }
              }
            }
          }
        }
        created_at
      }
    }
  }
`

export const useMessages = (filter: ThreadMessagesRequestFilterInput) => {
  const { data, loading, refetch } = useQuery(messagesQuery, {
    variables: { filter },
  })

  return {
    messagesPage: data?.messages as GptMessages,
    loading,
    refetch: () => refetch().then(noop),
  }
}

const sendMessageMutation = gql`
  mutation SendMessage($meetingId: String!, $message: String!) {
    sendMessage(meetingId: $meetingId, message: $message) {
      id
      role
      content {
        text {
          value
          annotations {
            __typename
            ... on GptFileCitation {
              text
              start_index
              end_index
              file_citation {
                filename
              }
            }
            ... on GptFilePath {
              text
              start_index
              end_index
              file_path {
                filename
              }
            }
          }
        }
      }
      created_at
    }
  }
`

export const useSendMessage = () => {
  const [sendMessage] = useMutation(sendMessageMutation)

  return (meetingId: string, message: string): Promise<GptMessage> =>
    sendMessage({ variables: { meetingId, message } }).then(
      response => response.data.sendMessage,
    )
}

const handleChatResponseQuery = gql`
  query HandleChatResponse($meetingId: String!) {
    handleChatResponse(meetingId: $meetingId) {
      id
      role
      content {
        text {
          value
          annotations {
            __typename
            ... on GptFileCitation {
              text
              start_index
              end_index
              file_citation {
                filename
              }
            }
            ... on GptFilePath {
              text
              start_index
              end_index
              file_path {
                filename
              }
            }
          }
        }
      }
      created_at
    }
  }
`
export const useHandleChatResponse = (meetingId: string) => {
  const variables = { meetingId }
  const [handle] = useLazyQuery(handleChatResponseQuery, {
    variables,
  })

  return (): Promise<GptMessage> =>
    handle(variables).then(response => response.data.handleChatResponse)
}
