import { AssociationContext } from '../../context/association-context'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import useFileUpload from '../../hooks/useFileUpload'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useUpdateAssociation } from '../../hooks/association/useAssociation'
import {
  Button,
  Col,
  Flex,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  Typography,
} from 'antd'
import styled from 'styled-components'
import {
  TwoColumnsContainer,
  TwoColumnsLeftContainer,
  TwoColumnsRightContainer,
} from '../molecules/two-columns'
import Panel from '../organism/panel'
import ImageUpload from '../molecules/img-upload'
import { InputLabel, InputLabelContainer } from '../atoms/input'
import moment from 'moment-timezone'
import { extensionFrom, humanize, removeTypename } from '../../utils/utils'
import { Media } from '../../types/media'
import { useBoolean } from '../../hooks/useBoolean'
import XhrPending from '../atoms/xhr-pending'
import {
  DeleteOutlined,
  DollarCircleOutlined,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
  TranslationOutlined,
} from '@ant-design/icons'
import {
  AssociationSensitiveTopic,
  AssociationStatus,
  AssociationType,
  Entry_String_String,
  MeetingFrequency,
  MeetingLocationType,
  MeetingProvider,
} from '../../types/graphql-types'
import Countries from 'country-list-js'
import * as Locales from 'locale-codes'
import Currencies from 'currency-list'
import _ from 'lodash'
import { Currency } from '../../types/currency'
import { t } from 'i18next'
import Spacer from '../atoms/spacer'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
`

const CommonDateFormats = [
  { label: '2021-08-05', value: 'yyyy-MM-dd' },
  { label: '2021_08_05', value: 'yyyy_MM_dd' },
  { label: '2021.08.05', value: 'yyyy.MM.dd' },
  { label: '8/5/21', value: 'M/d/yy' },
  { label: '08/05/21', value: 'MM/dd/yy' },
  { label: '08/05/2021', value: 'MM/dd/yyyy' },
  { label: '05 Aug 21', value: 'dd MMM yy' },
  { label: '05 Aug 2021', value: 'dd MMM yyyy' },
  { label: '05 August 2021', value: 'dd MMMM yyyy' },
  { label: 'Aug 5, 21', value: 'MMM d, yy' },
  { label: 'Aug 5, 2021', value: 'MMM d, yyyy' },
  { label: 'Aug 05, 2021', value: 'MMM dd, yyyy' },
  { label: 'August 5, 2021', value: 'MMMM d, yyyy' },
  { label: 'August 05, 2021', value: 'MMMM dd, yyyy' },
]

const AssociationOverview = () => {
  const { upload } = useFileUpload()
  const update = useUpdateAssociation()
  const { association, refetch } = useContext(AssociationContext)
  useDocumentTitle(`Settings - ${association?.name}`, {
    skip: !association,
  })
  const [xhrPending, toggleXhrPending] = useBoolean()
  const [name, setName] = useState(association?.name)
  const [type, setType] = useState(association?.type)
  const [description, setDescription] = useState(association?.description)
  const [logo, setLogo] = useState<string>()
  const [logoFile, setLogoFile] = useState<File>()
  const [status, setStatus] = useState(association?.status)

  const [address, setAddress] = useState(association?.address)
  const [city, setCity] = useState(association?.city)
  const [zip, setZip] = useState(association?.zip)
  const [state, setState] = useState(association?.state)
  const [country, setCountry] = useState(association?.country)
  const selectedCountry = useMemo(
    () => Countries.findByIso3(country),
    [country],
  )
  const [locale, setLocale] = useState(association?.locale)
  const [currency, setCurrency] = useState(association?.currency)

  const [phone, setPhone] = useState(association?.phone)
  const [email, setEmail] = useState(association?.email)
  const [website, setWebsite] = useState(association?.website)
  const [sensitiveTopics, setSensitiveTopics] = useState(
    association?.sensitiveTopics,
  )

  const [compositionDateFormat, setCompositionDateFormat] = useState(
    association?.compositionDateFormat,
  )
  const [
    compositionDetectParticipantLateArrival,
    setCompositionDetectParticipantLateArrival,
  ] = useState(association?.compositionDetectParticipantLateArrival)
  const [compositionIncludeActionItems, setCompositionIncludeActionItems] =
    useState(association?.compositionIncludeActionItems)
  const [
    compositionIncludeRestrictedItems,
    setCompositionIncludeRestrictedItems,
  ] = useState(association?.compositionIncludeRestrictedItems)
  const [compositionInstructions, setCompositionInstructions] = useState(
    association?.compositionInstructions,
  )
  const [compositionNumbersDecimalPlaces, setCompositionNumbersDecimalPlaces] =
    useState(association?.compositionNumbersDecimalPlaces)
  const [
    compositionOnlyIncludeDecimalsIfNonzero,
    setCompositionOnlyIncludeDecimalsIfNonzero,
  ] = useState(association?.compositionOnlyIncludeDecimalsIfNonzero)
  const [compositionTimeFormat, setCompositionTimeFormat] = useState(
    association?.compositionTimeFormat,
  )
  const [compositionWordingOverrides, setCompositionWordingOverrides] =
    useState<Entry_String_String[]>([])
  const [wordingOverrideChange, setWordingOverrideChange] = useState('')
  const [focusedWordOverrideKey, setFocusedWordOverrideKey] = useState('')
  const [focusedWordOverrideValue, setFocusedWordOverrideValue] = useState('')
  const [defaultMeetingLocationType, setDefaultMeetingLocationType] = useState(
    association?.defaultMeetingLocationType,
  )
  const [defaultMeetingProvider, setDefaultMeetingProvider] = useState(
    association?.defaultMeetingProvider,
  )
  const [defaultMeetingFrequency, setDefaultMeetingFrequency] = useState(
    association?.defaultMeetingFrequency,
  )
  const [defaultMeetingTimezone, setDefaultMeetingTimezone] = useState(
    association?.defaultMeetingTimezone,
  )

  const canSave = association && !!name && !!type

  useEffect(() => {
    if (association) {
      setName(association.name)
      setType(association.type)
      setDescription(association.description)
      setCompositionInstructions(association.compositionInstructions)
      setLogo(association.logo ?? '')
      setStatus(association.status)
      setAddress(association.address)
      setCity(association.city)
      setZip(association.zip)
      setState(association.state)
      setCountry(association.country)
      setLocale(association.locale)
      setCurrency(association.currency)
      setPhone(association.phone)
      setEmail(association.email)
      setWebsite(association.website)
      setSensitiveTopics(association.sensitiveTopics)
      setCompositionDateFormat(association.compositionDateFormat)
      setCompositionDetectParticipantLateArrival(
        association.compositionDetectParticipantLateArrival,
      )
      setCompositionIncludeActionItems(
        association.compositionIncludeActionItems,
      )
      setCompositionIncludeRestrictedItems(
        association.compositionIncludeRestrictedItems,
      )
      setCompositionNumbersDecimalPlaces(
        association.compositionNumbersDecimalPlaces,
      )
      setCompositionOnlyIncludeDecimalsIfNonzero(
        association.compositionOnlyIncludeDecimalsIfNonzero,
      )
      setCompositionTimeFormat(association.compositionTimeFormat)
      setCompositionWordingOverrides(
        association.compositionWordingOverrides
          ? (_.sortBy(
              removeTypename(association.compositionWordingOverrides),
              entry => entry.key,
            ) as Entry_String_String[])
          : [],
      )

      setDefaultMeetingLocationType(association.defaultMeetingLocationType)
      setDefaultMeetingProvider(association.defaultMeetingProvider)
      setDefaultMeetingFrequency(association.defaultMeetingFrequency)
      setDefaultMeetingTimezone(association.defaultMeetingTimezone)
    }
  }, [association])

  const save = () => {
    if (!canSave) return
    const uploadNewLogoIfAny = () =>
      logoFile
        ? upload(
            logoFile,
            `organizations/${association?.orgId}/associations/${association.id}/logo-${moment.now()}.${extensionFrom(logoFile)}`,
          ).then((media: Media) => media.url)
        : Promise.resolve(association?.logo ?? undefined)
    toggleXhrPending()
    uploadNewLogoIfAny()
      .then(logo =>
        update({
          id: association!.id,
          type,
          name,
          description,
          logo,
          status,
          address,
          city,
          state,
          zip,
          country,
          locale,
          currency,
          phone,
          email,
          website,
          sensitiveTopics,
          compositionDateFormat,
          compositionDetectParticipantLateArrival,
          compositionIncludeActionItems,
          compositionIncludeRestrictedItems,
          compositionInstructions,
          compositionNumbersDecimalPlaces,
          compositionOnlyIncludeDecimalsIfNonzero,
          compositionTimeFormat,
          compositionWordingOverrides,
          defaultMeetingLocationType,
          defaultMeetingProvider,
          defaultMeetingFrequency,
          defaultMeetingTimezone,
        }),
      )
      .then(refetch)
  }

  return (
    <XhrPending pending={xhrPending}>
      <Container>
        <Row align="middle" justify="end" gutter={8}>
          <Col>
            <Button type="primary" onClick={save} disabled={!canSave}>
              save
            </Button>
          </Col>
        </Row>
        <TwoColumnsContainer>
          <TwoColumnsLeftContainer>
            <Panel>
              <Row>
                <Col flex="auto">
                  <ImageUpload
                    url={logo || association?.logo || ''}
                    label="Logo"
                    name="association-logo"
                    onUrlChange={setLogo}
                    onFileChange={setLogoFile}
                  />
                </Col>
                <Col span={20}>
                  <Space
                    direction="vertical"
                    size="middle"
                    style={{ display: 'flex' }}
                  >
                    <Row align="middle" gutter={12}>
                      <Col span={18}>
                        <InputLabelContainer>
                          <InputLabel>Name</InputLabel>
                          <Input
                            value={name || association?.name}
                            onChange={e => setName(e.target.value)}
                            allowClear
                            required
                          />
                        </InputLabelContainer>
                      </Col>
                      <Col span={6}>
                        <InputLabelContainer>
                          <InputLabel>Type</InputLabel>
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            value={type ?? association?.type}
                            options={Object.values(AssociationType).map(
                              type => ({
                                label: humanize(type),
                                value: type,
                              }),
                            )}
                            onChange={setType}
                          />
                        </InputLabelContainer>
                      </Col>
                    </Row>
                    <InputLabelContainer>
                      <InputLabel>Country</InputLabel>
                      <Select
                        showSearch
                        value={country || association?.country}
                        options={Object.values(Countries.all)
                          .map((c: any) => ({
                            label: c.name,
                            value: c?.iso3,
                          }))
                          .sort((a, b) => a.label.localeCompare(b.label))}
                        onChange={setCountry}
                        allowClear
                      />
                    </InputLabelContainer>
                    <InputLabelContainer>
                      <InputLabel>Address</InputLabel>
                      <Input
                        value={address || association?.address || ''}
                        onChange={e => setAddress(e.target.value)}
                        allowClear
                      />
                    </InputLabelContainer>
                    <InputLabelContainer>
                      <InputLabel>City</InputLabel>
                      <Input
                        value={city || association?.city || ''}
                        onChange={e => setCity(e.target.value)}
                        allowClear
                      />
                    </InputLabelContainer>
                    <Row align="middle" gutter={12}>
                      {/*state*/}
                      <Col span={12}>
                        <InputLabelContainer>
                          <InputLabel>State/Province</InputLabel>
                          <Select
                            showSearch
                            value={state || association?.state || ''}
                            options={
                              selectedCountry?.provinces
                                ?.map((c: any) => c.name)
                                ?.map((c: string) => ({
                                  label: c,
                                  value: c,
                                })) ?? []
                            }
                            onChange={setState}
                            allowClear
                          />
                        </InputLabelContainer>
                      </Col>
                      {/*zip*/}
                      <Col span={12}>
                        <InputLabelContainer>
                          <InputLabel>Zip</InputLabel>
                          <Input
                            value={zip || association?.zip || ''}
                            onChange={e => setZip(e.target.value)}
                            allowClear
                          />
                        </InputLabelContainer>
                      </Col>
                    </Row>
                  </Space>
                </Col>
              </Row>
            </Panel>
            <Spacer />
            <Panel title="Meeting">
              <Row align="middle" gutter={24}>
                <Col span={12}>
                  <InputLabelContainer>
                    <InputLabel>Default meeting mode</InputLabel>
                    <Select
                      value={defaultMeetingLocationType}
                      onChange={setDefaultMeetingLocationType}
                      options={Object.values(MeetingLocationType).map(type => ({
                        label: humanize(type),
                        value: type,
                      }))}
                    />
                  </InputLabelContainer>
                </Col>
                <Col span={12}>
                  <InputLabelContainer>
                    <InputLabel>Default meeting provider</InputLabel>
                    <Select
                      value={defaultMeetingProvider}
                      onChange={setDefaultMeetingProvider}
                      options={Object.values(MeetingProvider).map(provider => ({
                        label: humanize(provider),
                        value: provider,
                      }))}
                    />
                  </InputLabelContainer>
                </Col>
              </Row>
              <Row align="middle" gutter={24}>
                <Col span={12}>
                  <InputLabelContainer>
                    <InputLabel>Default meeting frequency</InputLabel>
                    <Select
                      value={defaultMeetingFrequency}
                      onChange={setDefaultMeetingFrequency}
                      options={Object.values(MeetingFrequency).map(
                        frequency => ({
                          label: humanize(frequency),
                          value: frequency,
                        }),
                      )}
                    />
                  </InputLabelContainer>
                </Col>
                <Col span={12}>
                  <InputLabelContainer>
                    <InputLabel>Default meeting timezone</InputLabel>
                    <Select
                      showSearch
                      value={defaultMeetingTimezone}
                      onChange={setDefaultMeetingTimezone}
                      options={moment.tz.names().map(timezone => ({
                        label: timezone,
                        value: timezone,
                      }))}
                    />
                  </InputLabelContainer>
                </Col>
              </Row>
            </Panel>
          </TwoColumnsLeftContainer>
          <TwoColumnsRightContainer style={{ gap: 12 }}>
            <Panel>
              <Flex
                align="center"
                justify="space-between"
                style={{ width: '100%' }}
              >
                <Tag
                  key={status}
                  color={
                    !status
                      ? 'gray'
                      : status === AssociationStatus.Active
                        ? 'green'
                        : 'red'
                  }
                >
                  {humanize(status)}
                </Tag>
                <Switch
                  checked={status === AssociationStatus.Active}
                  onChange={checked => {
                    const promise = checked
                      ? update({
                          id: association!.id,
                          status: AssociationStatus.Active,
                        })
                      : update({
                          id: association!.id,
                          status: AssociationStatus.Inactive,
                        })
                    const confirmed = window.confirm(
                      `Are you sure you want to ${checked ? 'Activate' : 'disable'} ${association?.name}?`,
                    )
                    if (confirmed)
                      promise.then(() =>
                        setStatus(
                          checked
                            ? AssociationStatus.Active
                            : AssociationStatus.Inactive,
                        ),
                      )
                  }}
                />
              </Flex>
            </Panel>
            <Panel>
              <Input
                addonBefore={
                  <InputLabel>
                    <PhoneOutlined />
                  </InputLabel>
                }
                prefix={
                  selectedCountry?.dialing_code
                    ? selectedCountry.dialing_code
                    : ''
                }
                placeholder="Phone number"
                value={phone || association?.phone || ''}
                onChange={e => setPhone(e.target.value)}
                allowClear
              />
              <Input
                addonBefore={
                  <InputLabel>
                    <MailOutlined />
                  </InputLabel>
                }
                placeholder="Email"
                value={email || association?.email || ''}
                onChange={e => setEmail(e.target.value)}
                allowClear
              />
              <Input
                addonBefore={
                  <InputLabel>
                    <GlobalOutlined />
                  </InputLabel>
                }
                placeholder="Website"
                value={website || association?.website || ''}
                onChange={e => setWebsite(e.target.value)}
                allowClear
              />
            </Panel>
            <Panel>
              <Flex gap={24} align="center">
                <InputLabel>
                  <TranslationOutlined />
                </InputLabel>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Language"
                  value={locale}
                  options={_.uniqBy(Locales.all, 'tag').map(l => ({
                    label: l.name + (l.location ? ` (${l.location})` : ''),
                    value: l.tag,
                  }))}
                  onChange={setLocale}
                  allowClear
                />
              </Flex>
              <Flex gap={24} align="center">
                <InputLabel>
                  <DollarCircleOutlined />
                </InputLabel>
                <Select
                  showSearch
                  placeholder="Currency"
                  value={currency}
                  style={{ width: '100%' }}
                  options={_.uniqBy(
                    _.values(Currencies.getAll('en')) as Currency[],
                    'code',
                  )
                    .filter(c => !!c.code)
                    .map(c => ({
                      label: (
                        <Row key={c.code} align="middle" gutter={12}>
                          <Col>
                            <Typography.Text strong>{c.symbol}</Typography.Text>
                          </Col>
                          <Col>
                            <Typography.Text>{c.name}</Typography.Text>
                          </Col>
                        </Row>
                      ),
                      value: c.code,
                    }))}
                  onChange={setCurrency}
                  allowClear
                />
              </Flex>
            </Panel>
          </TwoColumnsRightContainer>
        </TwoColumnsContainer>
        <Panel
          title="Intelligence"
          subTitle="This information is used to provide more accurate content generation."
        >
          <Row align="middle" gutter={24}>
            <Col span={12}>
              <InputLabelContainer>
                <InputLabel>Business Summary</InputLabel>
                <Input.TextArea
                  value={description || association?.description || ''}
                  placeholder="Describe the association industry, operations and market..."
                  onChange={e => setDescription(e.target.value)}
                  autoSize={{ minRows: 6, maxRows: 9 }}
                />
              </InputLabelContainer>
            </Col>
            <Col span={12}>
              <InputLabelContainer>
                <InputLabel>Minutes Generation Instructions</InputLabel>
                <Input.TextArea
                  value={compositionInstructions || ''}
                  placeholder="Describe how to generate minutes, the specific features and requirements..."
                  onChange={e => setCompositionInstructions(e.target.value)}
                  autoSize={{ minRows: 6, maxRows: 9 }}
                />
              </InputLabelContainer>
            </Col>
          </Row>
          <Row align="middle" gutter={[24, 24]} justify="space-between">
            <Col span={12}>
              <Row align="middle" gutter={[24, 24]}>
                <Col span={12}>
                  <InputLabelContainer>
                    <InputLabel>Date Format</InputLabel>
                    <Select
                      value={compositionDateFormat}
                      options={CommonDateFormats}
                      onChange={setCompositionDateFormat}
                    />
                  </InputLabelContainer>
                </Col>
                <Col span={12}>
                  <InputLabelContainer>
                    <InputLabel>Time Format</InputLabel>
                    <Select
                      value={compositionTimeFormat}
                      options={[
                        { label: '12-hour', value: 'h:mm a' },
                        { label: '24-hour', value: 'HH:mm' },
                      ]}
                      onChange={setCompositionTimeFormat}
                    />
                  </InputLabelContainer>
                </Col>
                <Col>
                  <InputLabelContainer>
                    <InputLabel>Decimal Places</InputLabel>
                    <Input
                      type="number"
                      value={compositionNumbersDecimalPlaces}
                      onChange={e =>
                        setCompositionNumbersDecimalPlaces(
                          Number(e.target.value),
                        )
                      }
                    />
                  </InputLabelContainer>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row align="middle" gutter={[24, 12]}>
                <Col>
                  <InputLabelContainer>
                    <InputLabel>Detect Participant Late Arrival</InputLabel>
                  </InputLabelContainer>
                  <Switch
                    checked={compositionDetectParticipantLateArrival}
                    onChange={setCompositionDetectParticipantLateArrival}
                  />
                </Col>
                <Col>
                  <InputLabelContainer>
                    <InputLabel>Include Action Items</InputLabel>
                  </InputLabelContainer>
                  <Switch
                    checked={compositionIncludeActionItems}
                    onChange={setCompositionIncludeActionItems}
                  />
                </Col>
                <Col>
                  <InputLabelContainer>
                    <InputLabel>Include Restricted Items</InputLabel>
                  </InputLabelContainer>
                  <Switch
                    title={'Include Restricted Items'}
                    checked={compositionIncludeRestrictedItems}
                    onChange={setCompositionIncludeRestrictedItems}
                  />
                </Col>
                <Col>
                  <InputLabelContainer>
                    <InputLabel>Only Include Decimals If Nonzero</InputLabel>
                  </InputLabelContainer>
                  <Switch
                    checked={compositionOnlyIncludeDecimalsIfNonzero}
                    onChange={setCompositionOnlyIncludeDecimalsIfNonzero}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={12}>
              <InputLabelContainer>
                <InputLabel>Sensitive Topics</InputLabel>
                <Select
                  mode="tags"
                  value={sensitiveTopics}
                  placeholder="Sensitive Topics"
                  onChange={setSensitiveTopics}
                  options={Object.values(AssociationSensitiveTopic).map(
                    topic => ({
                      label: t(`sensitive_topic_${topic}`),
                      value: topic,
                    }),
                  )}
                />
              </InputLabelContainer>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <InputLabel>Word overrides</InputLabel>
              <Row align="middle" gutter={12}>
                <Col span={12}>
                  <Typography.Text type="secondary">Replace</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text type="secondary">With</Typography.Text>
                </Col>
              </Row>
              <Space
                direction="vertical"
                size="small"
                style={{ width: '100%' }}
              >
                {compositionWordingOverrides
                  ?.map(entry => entry!!)
                  ?.map(({ key, value }) => (
                    <Row key={key} align="middle" gutter={12}>
                      {/* key */}
                      <Col span={12}>
                        <Input
                          value={
                            wordingOverrideChange &&
                            focusedWordOverrideKey === key!!
                              ? wordingOverrideChange
                              : key!!
                          }
                          onFocus={() => setFocusedWordOverrideKey(key!!)}
                          onChange={e =>
                            setWordingOverrideChange(e.target.value)
                          }
                          onBlur={e => {
                            setFocusedWordOverrideKey('')
                            setWordingOverrideChange('')
                            setCompositionWordingOverrides(prev =>
                              prev.map(item =>
                                item!.key === key
                                  ? { key: e.target.value, value: item!.value }
                                  : item,
                              ),
                            )
                          }}
                        />
                      </Col>
                      {/* value */}
                      <Col span={10}>
                        <Input
                          value={
                            wordingOverrideChange &&
                            focusedWordOverrideValue === value!!
                              ? wordingOverrideChange
                              : value!!
                          }
                          onFocus={() => setFocusedWordOverrideValue(value!!)}
                          onChange={e =>
                            setWordingOverrideChange(e.target.value)
                          }
                          onBlur={e => {
                            setFocusedWordOverrideValue('')
                            setWordingOverrideChange('')
                            setCompositionWordingOverrides(prev =>
                              prev.map(item =>
                                item!.key === key
                                  ? { key: item!.key, value: e.target.value }
                                  : item,
                              ),
                            )
                          }}
                        />
                      </Col>
                      <Col span={2}>
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            setCompositionWordingOverrides(prev =>
                              prev.filter(item => item!.key !== key),
                            )
                          }}
                        />
                      </Col>
                    </Row>
                  ))}
                <Button
                  type="primary"
                  style={{ width: '100%' }}
                  onClick={() => {
                    setCompositionWordingOverrides([
                      ...compositionWordingOverrides,
                      {
                        key: '',
                        value: '',
                      },
                    ])
                  }}
                >
                  Add Wording Override
                </Button>
              </Space>
            </Col>
          </Row>
        </Panel>
      </Container>
    </XhrPending>
  )
}

export default AssociationOverview
