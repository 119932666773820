import React, { useContext, useState } from 'react'
import { OrganizationContext } from '../../context/organization-context'
import styled from 'styled-components'
import Panel from '../organism/panel'
import { useBoolean } from '../../hooks/useBoolean'
import XhrPending from '../atoms/xhr-pending'
import useFileUpload from '../../hooks/useFileUpload'
import ImageUpload from '../molecules/img-upload'
import { Col, Input, Row, Space } from 'antd'
import { InputLabel, InputLabelContainer } from '../atoms/input'
import { isEqual } from 'lodash'
import { extensionFrom, omitUndefined } from '../../utils/utils'
import Button from '../atoms/button'
import { useEditOrganization } from '../../hooks/organization/useOrganization'
import moment from 'moment-timezone'
import { Media } from '../../types/media'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import {
  TwoColumnsContainer,
  TwoColumnsLeftContainer,
} from '../molecules/two-columns'
import useInput from '../../hooks/useInput'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
`

const OrganizationIdentity = () => {
  const { membership, refetch } = useContext(OrganizationContext)!
  const org = membership!.organization
  const [xhrPending, toggleXhrPending] = useBoolean()
  const [name, setName] = useInput<string>()
  const [logo, setLogo] = useState<string>()
  const [logoFile, setLogoFile] = useState<File>()
  const { upload } = useFileUpload()
  const edit = useEditOrganization()
  useDocumentTitle(`Settings - ${org?.name}`, {
    skip: !org,
  })

  const updatable = {
    ...org,
    ...omitUndefined({
      name,
      logo,
    }),
  }
  const hasChanged = !isEqual(updatable, org) || logoFile
  const canSave = !xhrPending && hasChanged

  const save = () => {
    if (canSave) {
      const uploadNewLogoIfAny = () =>
        logoFile
          ? upload(
              logoFile,
              `organizations/${org.id}/logo-${moment.now()}.${extensionFrom(logoFile)}`,
            )
          : Promise.resolve({ url: org?.logo } as Media)
      toggleXhrPending()
      uploadNewLogoIfAny()
        .then(media =>
          edit({
            id: org.id,
            logo: media.url,
            name,
          }),
        )
        .then(refetch)
        .finally(toggleXhrPending)
    }
  }

  return (
    <XhrPending pending={xhrPending}>
      <Container>
        <Row align="middle" justify="end" gutter={8}>
          <Col>
            <Button
              analyticsId="update_organization"
              label="save"
              onClick={save}
              disabled={!canSave}
            />
          </Col>
        </Row>
        <TwoColumnsContainer>
          <TwoColumnsLeftContainer>
            <Panel>
              <Row>
                <Col flex="auto">
                  <ImageUpload
                    url={logo ?? org.logo ?? ''}
                    label="Logo"
                    name="organization-logo"
                    onUrlChange={setLogo}
                    onFileChange={setLogoFile}
                  />
                </Col>
                <Col span={20}>
                  <Space
                    direction="vertical"
                    size="middle"
                    style={{ display: 'flex' }}
                  >
                    <InputLabelContainer>
                      <InputLabel>Name</InputLabel>
                      <Input
                        value={name ?? org.name}
                        onChange={setName}
                        allowClear
                        required
                      />
                    </InputLabelContainer>
                  </Space>
                </Col>
              </Row>
            </Panel>
          </TwoColumnsLeftContainer>
        </TwoColumnsContainer>
      </Container>
    </XhrPending>
  )
}

export default OrganizationIdentity
