import { Media } from '../types/media'
import { useContext } from 'react'
import { AppContext } from '../context/application-context'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'

const useFileUpload = () => {
  const { firebase } = useContext(AppContext)

  const storage = getStorage(firebase!!)
  storage.maxUploadRetryTime = 5000
  const upload = (file: File, filename: string): Promise<Media> =>
    uploadBytes(ref(storage, filename), file)
      .then(snapshot => getDownloadURL(snapshot.ref))
      .then(url => ({ url }) as Media)
  return { upload }
}

export default useFileUpload
