import styled from 'styled-components'
import { Spin } from 'antd'
import React, { PropsWithChildren } from 'react'

type Props = {
  pending: boolean
}
const Container = styled.div`
  height: 100%;
  .ant-spin-container,
  .ant-spin-nested-loading,
  .ant-spin-nested-loading > div,
  .ant-spin-nested-loading > div > div {
    height: 100%;
  }
  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 100%;
  }
`

const XhrPending: React.FC<Props & PropsWithChildren> = ({
  pending,
  children,
}) => (
  <Container>
    <Spin size="large" spinning={pending} style={{ width: '100%' }}>
      {children}
    </Spin>
  </Container>
)

export default XhrPending
