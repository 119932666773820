import { InboxOutlined } from '@ant-design/icons'
import { Typography, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { noop } from 'lodash'

export enum FileTypes {
  PDF = 'PDF',
  DOCX = 'DOCX',
  MP3 = 'MP3',
  MP4 = 'MP4',
  VTT = 'VTT',
}

export const contentTypePerFileType = {
  [FileTypes.PDF]: 'application/pdf',
  [FileTypes.DOCX]:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  [FileTypes.MP3]: 'audio/mpeg',
  [FileTypes.MP4]: 'video/mp4',
  [FileTypes.VTT]: 'text/vtt',
}

type Props = {
  supportedFileTypes: FileTypes[]
  onChange: (files: File[]) => void
  message?: React.ReactNode | string
}

const FileUploader: React.FC<Props> = ({
  supportedFileTypes,
  onChange,
  message,
}) => {
  const [fileList, setFileList] = useState<File[]>([])

  const handleBeforeUpload = (file: File) => {
    setFileList(prevList => [...prevList, file])
    return false // Prevent the upload
  }

  const handleChange = () => {
    onChange(fileList)
  }

  useEffect(() => {
    onChange(fileList)
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList])

  return (
    <Upload.Dragger
      name="file"
      multiple
      customRequest={noop}
      accept={supportedFileTypes
        .map(type => contentTypePerFileType[type])
        .join(',')}
      beforeUpload={handleBeforeUpload}
      onChange={handleChange}
      onRemove={file => {
        setFileList(prev => prev.filter(f => f.name !== file.name))
      }}
      fileList={fileList as any} // Trick to use fileList without uploading
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        {message ?? 'Click or drag file to this area to upload'}
      </p>
      <Typography.Text type="secondary">
        Supported file types are {supportedFileTypes.join(', ')}
      </Typography.Text>
    </Upload.Dragger>
  )
}

export default FileUploader
