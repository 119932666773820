import { gql } from '@apollo/client'
import {
  Document,
  DocumentRequestFilterInput,
  NewDocumentInput,
} from '../../types/graphql-types'
import useQuery from '../useQuery'
import { Page } from '../../types/pageable'
import { noop } from 'lodash'
import useMutation from '../useMutation'

const documentsQuery = gql`
  query Documents($filter: DocumentRequestFilterInput!) {
    documents(filter: $filter) {
      data {
        id
        name
        url
        createdAt
        updatedAt
      }
      next
    }
  }
`

export const useDocuments = (filter: DocumentRequestFilterInput) => {
  const { data, loading, error, refetch } = useQuery(documentsQuery, {
    variables: { filter },
  })

  return {
    page: data?.documents as Page<Document>,
    loading,
    error,
    refetch: () => refetch().then(noop),
  }
}

const createDocumentMutation = gql`
  mutation CreateDocument($documents: [NewDocumentInput!]!) {
    createDocuments(documents: $documents) {
      id
    }
  }
`

export const useCreateDocument = () => {
  const [createDocuments] = useMutation(createDocumentMutation)

  return (documents: NewDocumentInput[]) =>
    createDocuments({ variables: { documents } }).then(({ data }) =>
      data.createDocuments.map((doc: Document) => doc.id),
    )
}

const linkDocumentToMeetingMutation = gql`
  mutation LinkDocumentToMeeting($documentId: String!, $meetingId: String!) {
    linkDocumentToMeeting(documentId: $documentId, meetingId: $meetingId)
  }
`
const unlinkDocumentFromMeetingMutation = gql`
  mutation UnlinkDocumentFromMeeting(
    $documentId: String!
    $meetingId: String!
  ) {
    unlinkDocumentFromMeeting(documentId: $documentId, meetingId: $meetingId)
  }
`
const linkDocumentToCompositionMutation = gql`
  mutation LinkDocumentToComposition(
    $documentId: String!
    $meetingId: String!
    $compositionId: String!
  ) {
    linkDocumentToComposition(
      documentId: $documentId
      meetingId: $meetingId
      compositionId: $compositionId
    )
  }
`
const unlinkDocumentFromCompositionMutation = gql`
  mutation UnlinkDocumentFromComposition(
    $documentId: String!
    $meetingId: String!
    $compositionId: String!
  ) {
    unlinkDocumentFromComposition(
      documentId: $documentId
      meetingId: $meetingId
      compositionId: $compositionId
    )
  }
`
export const useDocumentLinking = () => {
  const [linkDocumentToMeeting] = useMutation(linkDocumentToMeetingMutation)
  const [unlinkDocumentFromMeeting] = useMutation(
    unlinkDocumentFromMeetingMutation,
  )
  const [linkDocumentToComposition] = useMutation(
    linkDocumentToCompositionMutation,
  )
  const [unlinkDocumentFromComposition] = useMutation(
    unlinkDocumentFromCompositionMutation,
  )

  return {
    linkDocumentToMeeting: (documentId: string, meetingId: string) =>
      linkDocumentToMeeting({
        variables: { documentId, meetingId },
      }),
    unlinkDocumentFromMeeting: (documentId: string, meetingId: string) =>
      unlinkDocumentFromMeeting({
        variables: { documentId, meetingId },
      }),
    linkDocumentToComposition: (
      documentId: string,
      meetingId: string,
      compositionId: string,
    ) =>
      linkDocumentToComposition({
        variables: { documentId, meetingId, compositionId },
      }),
    unlinkDocumentFromComposition: (
      documentId: string,
      meetingId: string,
      compositionId: string,
    ) =>
      unlinkDocumentFromComposition({
        variables: { documentId, meetingId, compositionId },
      }),
  }
}
