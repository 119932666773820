import { Avatar, Select, Space, Typography } from 'antd'
import React from 'react'
import { InboxOutlined } from '@ant-design/icons'
import { Association } from '../../types/graphql-types'

type Props = {
  activeAssociationId?: string
  loading?: boolean
  associations: Association[]
  onChange: (id: string) => void
}

const AssociationSelect: React.FC<Props> = ({
  loading,
  activeAssociationId,
  associations,
  onChange,
}) => (
  <Select
    loading={loading}
    allowClear={false}
    variant="filled"
    size="large"
    style={{ width: '100%' }}
    value={activeAssociationId}
    onChange={id => {
      localStorage.setItem('activeAssociationId', id as string)
      onChange(id as string)
    }}
    dropdownRender={menu => (
      <>
        {menu}
        {/*TODO: Enable once the create new association form is ready */}
        {/*<Divider style={{ margin: '4px 0' }} />*/}
        {/*<Button*/}
        {/*  analyticsId="create_new_association"*/}
        {/*  label="+ create new association"*/}
        {/*  type="text"*/}
        {/*  href="/organization/associations?new=true"*/}
        {/*/>*/}
      </>
    )}
    options={associations.map(association => ({
      value: association.id,
      label: (
        <Space direction="horizontal">
          <Avatar
            size="small"
            src={association?.logo}
            icon={<InboxOutlined />}
          />
          <Typography.Text strong>{association?.name}</Typography.Text>
        </Space>
      ),
    }))}
  />
)

export default AssociationSelect
