import { Input as AntInput } from 'antd'
import styled from 'styled-components'
import { Label } from './typography'

/**
 * @deprecated
 */
export const Input = styled(AntInput)<{ $highlight?: boolean }>`
  padding: 8px;
`

/**
 * @deprecated
 */
export const PasswordInput = styled(AntInput.Password)`
  padding: 8px;
`

/**
 * @deprecated
 */
export const Textarea = styled(AntInput.TextArea)<{ $highlight?: boolean }>`
  padding: 8px;
`
export const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: inherit;
  gap: 8px;
`

export const InputLabel = styled(Label)``
