import { Button, Card, Col, Flex, Input, List, Row, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { ReloadOutlined, RobotOutlined, SendOutlined } from '@ant-design/icons'
import {
  useCreateThread,
  useHandleChatResponse,
  useMessages,
  useSendMessage,
} from '../../hooks/chat/useChat'
import { useBoolean } from '../../hooks/useBoolean'
import { GptMessage, GptMessageRole } from '../../types/graphql-types'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import Markdown from 'react-markdown'
import Spacer from '../atoms/spacer'
import Loading from '../atoms/loading'
import _, { noop } from 'lodash'

type Props = {
  meetingId: string
}

type Prompt = {
  title: string
  subtitle: string
  prompt: string
}

const prompts: Prompt[] = [
  {
    title: 'Key Items',
    subtitle: 'Extract summaries from the meeting',
    prompt:
      'Extract summaries from the provided meeting transcript. Ignore the agenda and organize them by discussed item. Ensure each summary is clearly associated with its relevant item for easy reference.',
  },
  {
    title: 'Motions',
    subtitle: 'Extract motions from the meeting per discussed item',
    prompt:
      'Extract motions from the provided meeting transcript. Ignore the agenda and Organize them by discussed item.',
  },
  {
    title: 'Decisions',
    subtitle: 'Extract decisions from the meeting per discussed item',
    prompt:
      'Extract decisions from the provided meeting transcript. Ignore the agenda and Organize them by discussed item. Ensure each decision is clearly associated with its relevant item for easy reference.',
  },
  {
    title: 'Action Items',
    subtitle: 'Extract action items from the meeting per discussed item',
    prompt:
      'Extract action items from the provided meeting transcript. Ignore the agenda and Organize them by discussed item. Ensure each action item is clearly associated with its relevant item for easy reference.',
  },
]

const addAndReturnAdded = (arr: Set<string>, item?: string) => {
  if (item) arr.add(item)
  return item
}

type PromptCardProps = {
  prompt: Prompt
  handleSendMessage: (msg: string) => void
  xhrPending?: boolean
}

const PromptCard: React.FC<PromptCardProps> = ({
  prompt,
  xhrPending,
  handleSendMessage,
}) => (
  <Card
    hoverable
    type="inner"
    size="small"
    style={{ cursor: xhrPending ? 'not-allowed' : 'pointer' }}
    onClick={xhrPending ? noop : () => handleSendMessage(prompt.prompt)}
  >
    <Card.Meta title={prompt.title} description={prompt.subtitle} />
  </Card>
)

const LoadingCard = () => (
  <Card
    loading
    size="small"
    title={<RobotOutlined style={{ textAlign: 'left' }} />}
  />
)

const MeetingIntelligenceAssistant: React.FC<Props> = ({ meetingId }) => {
  const [message, setMessage] = useState('')
  const createThread = useCreateThread()
  const sendMessage = useSendMessage()
  const handleChatResponse = useHandleChatResponse(meetingId)
  const [responsePending, toggleResponsePending] = useBoolean(false)
  const [messages, setMessages] = useState<GptMessage[]>([])
  const [after, setAfter] = useState<string>()
  const { loading, messagesPage } = useMessages({ meetingId })
  const usedPrompts = useRef<Set<string>>(new Set())

  const hasNextPage = messagesPage?.has_more
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setAfter(messagesPage?.last_id ?? undefined),
    disabled: !loading && !messagesPage,
    rootMargin: '0px 0px 400px 0px',
  })

  useEffect(() => {
    if (messagesPage) {
      const data = (messagesPage?.data ?? []) as GptMessage[]
      setMessages(prev => (after ? [...prev, ...data] : data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesPage])

  const handleSendMessage = (msg: string = message) => {
    if (!msg) return

    toggleResponsePending()
    if (messages.length === 0) {
      createThread({ meetingId, message: msg })
        .then(msgs => {
          setMessages(msgs)
          setMessage('')
        })
        .finally(toggleResponsePending)
    } else {
      sendMessage(meetingId, msg)
        .then(msg => {
          setMessages(prev => _.uniqBy([...prev, msg], 'id'))
          setMessage('')
        })
        .then(handleChatResponse)
        .then(msg => {
          if (msg) setMessages(prev => _.uniqBy([...prev, msg], 'id'))
        })
        .finally(toggleResponsePending)
    }
  }

  return (
    <Flex vertical style={{ height: '100%' }} gap={12}>
      <List
        loading={loading}
        itemLayout="vertical"
        style={{ flex: 1, overflow: 'auto' }}
        split={false}
        bordered={false}
        dataSource={messages}
        locale={{
          emptyText: responsePending ? (
            <LoadingCard />
          ) : (
            <Flex vertical gap={18}>
              <Typography.Title level={2}>💡</Typography.Title>
              <Typography.Text type="secondary">
                Get fast insights from your meeting by selecting a prompt or
                asking a question
              </Typography.Text>
              <Row wrap gutter={[12, 12]} align="middle" justify="center">
                {prompts.map(prompt => (
                  <Col key={prompt.title} span={12}>
                    <PromptCard
                      prompt={prompt}
                      handleSendMessage={handleSendMessage}
                      xhrPending={responsePending}
                    />
                  </Col>
                ))}
              </Row>
            </Flex>
          ),
        }}
        renderItem={(item, index) => (
          <>
            <List.Item>
              <Card
                size="small"
                hoverable={false}
                bordered={item.role === GptMessageRole.Assistant}
                style={{
                  width:
                    item.role === GptMessageRole.User ? 'fit-content' : '100%',
                  maxWidth: item.role === GptMessageRole.User ? '77%' : '100%',
                  marginLeft:
                    item.role === GptMessageRole.User ? 'auto' : 'unset',
                }}
                styles={
                  item.role === GptMessageRole.User
                    ? {
                        body: {
                          backgroundColor: '#393E41',
                          color: 'white',
                          borderRadius: '10px',
                        },
                      }
                    : {
                        body: { backgroundColor: '#f4f5f6' },
                      }
                }
                title={
                  item.role === GptMessageRole.Assistant ? (
                    <RobotOutlined />
                  ) : undefined
                }
              >
                {item.content
                  .map(content => content?.text?.value)
                  .filter(Boolean)
                  .map(text => (
                    <Markdown key={text}>
                      {addAndReturnAdded(
                        usedPrompts.current,
                        prompts.find(p => p.prompt === text)?.subtitle,
                      ) ?? text}
                    </Markdown>
                  ))}
              </Card>
              {!responsePending &&
                index === messages.length - 1 &&
                item.role === GptMessageRole.User && (
                  <Flex justify="end">
                    <Button
                      type="text"
                      onClick={() => {
                        toggleResponsePending()
                        handleChatResponse()
                          .then(msg => {
                            if (msg) setMessages(prev => [...prev, msg])
                          })
                          .finally(toggleResponsePending)
                      }}
                    >
                      <ReloadOutlined />
                    </Button>
                  </Flex>
                )}
            </List.Item>
            {responsePending && index === messages.length - 1 && (
              <LoadingCard />
            )}
            {!responsePending &&
              item.role === GptMessageRole.Assistant &&
              index === messages.length - 1 &&
              prompts.filter(p => !usedPrompts.current.has(p.subtitle)).length >
                0 && (
                <Flex vertical gap={12} style={{ padding: 24 }}>
                  <Typography.Text type="secondary">
                    Follow up with a prompt or ask a question
                  </Typography.Text>
                  <Row wrap gutter={[8, 8]}>
                    {prompts
                      .filter(p => !usedPrompts.current.has(p.subtitle))
                      .map(p => (
                        <Col key={p.title} span={8}>
                          <PromptCard
                            prompt={p}
                            handleSendMessage={handleSendMessage}
                          />
                        </Col>
                      ))}
                  </Row>
                </Flex>
              )}
          </>
        )}
        footer={
          <>
            {hasNextPage && <div ref={sentryRef} />}
            {loading && messages.length > 0 && (
              <Spacer>
                <Loading />
              </Spacer>
            )}
          </>
        }
      />
      <Input
        disabled={responsePending}
        size="large"
        variant="filled"
        placeholder="Type a message"
        value={message}
        onChange={e => setMessage(e.target.value)}
        onPressEnter={() => handleSendMessage()}
        addonAfter={
          <Button
            type="text"
            size="small"
            onClick={() => handleSendMessage()}
            loading={responsePending}
          >
            <SendOutlined />
          </Button>
        }
      />
    </Flex>
  )
}

export default MeetingIntelligenceAssistant
