import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import LoadingPage from '../components/pages/loading'
import { useQueryParam } from '../hooks/useQueryParam'
import { noop } from 'lodash'
import { Association } from '../types/graphql-types'
import { useAssociations } from '../hooks/association/useAssociation'
import AssociationCreatePage from '../components/pages/association-first-create'
import { useLocation } from 'react-router-dom'

type AssociationContextProps = {
  associations: Association[]
  association?: Association
  refetch: () => Promise<void>
  setAssociationId: (value: string | undefined) => void
}

export const AssociationContext = React.createContext<AssociationContextProps>({
  associations: [],
  refetch: Promise.reject,
  setAssociationId: noop,
})

export const AssociationContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [associationId, setAssociationId] = useQueryParam<string>('ass')
  const { loading, associations, refetch } = useAssociations()
  const [association, setAssociation] = useState<Association>()
  const hasAssociation = associations?.length > 0
  const location = useLocation()

  // Sync associationId with URL and ensure ass param is preserved
  useEffect(() => {
    const storageAssociationId = localStorage.getItem('activeAssociationId')
    if (!associationId && storageAssociationId) {
      setAssociationId(storageAssociationId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const associationsByIds: Record<string, Association> = useMemo(
    () =>
      associations?.reduce((byId, item) => ({ ...byId, [item.id]: item }), {}),
    [associations],
  )

  useEffect(() => {
    if (!association && hasAssociation) {
      const storageActiveAssociationId = localStorage.getItem(
        'activeAssociationId',
      )
      setAssociation(
        storageActiveAssociationId
          ? associationsByIds[storageActiveAssociationId]
          : associationsByIds[associationId] ?? associations[0],
      )
    } else if (association) {
      setAssociation(associationsByIds[association.id])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associations])

  useEffect(() => {
    if (associationId) {
      setAssociation(associationsByIds[associationId])
      localStorage.setItem('activeAssociationId', associationId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associationId])

  return loading || (hasAssociation && !association) ? (
    <LoadingPage />
  ) : hasAssociation ? (
    <AssociationContext.Provider
      value={{
        associations,
        association,
        refetch,
        setAssociationId,
      }}
    >
      {children}
    </AssociationContext.Provider>
  ) : (
    <AssociationCreatePage onSuccess={refetch} />
  )
}
