import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Scalar for BigDecimal */
  BigDecimal: { input: any; output: any; }
  /** Scalar for BigInteger */
  BigInteger: { input: any; output: any; }
  /** Scalar for DateTime */
  DateTime: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  avatar?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** ISO-8601 */
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
  whitelisted: Scalars['Boolean']['output'];
};

export type Association = {
  __typename?: 'Association';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  compositionDateFormat: Scalars['String']['output'];
  compositionDetectParticipantLateArrival: Scalars['Boolean']['output'];
  compositionIncludeActionItems: Scalars['Boolean']['output'];
  compositionIncludeRestrictedItems: Scalars['Boolean']['output'];
  compositionInstructions?: Maybe<Scalars['String']['output']>;
  compositionNumbersDecimalPlaces: Scalars['Int']['output'];
  compositionOnlyIncludeDecimalsIfNonzero: Scalars['Boolean']['output'];
  compositionTimeFormat: Scalars['String']['output'];
  compositionWordingOverrides: Array<Maybe<Entry_String_String>>;
  country?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  defaultMeetingFrequency?: Maybe<MeetingFrequency>;
  defaultMeetingLocationType: MeetingLocationType;
  defaultMeetingProvider?: Maybe<MeetingProvider>;
  defaultMeetingTimezone?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  locale?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  sensitiveTopics: Array<Maybe<AssociationSensitiveTopic>>;
  state?: Maybe<Scalars['String']['output']>;
  status: AssociationStatus;
  timezone?: Maybe<Scalars['String']['output']>;
  type: AssociationType;
  /** ISO-8601 */
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};


export type AssociationCompositionWordingOverridesArgs = {
  key?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AssociationMember = {
  __typename?: 'AssociationMember';
  associationId: Scalars['String']['output'];
  /** ISO-8601 */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  role: AssociationMemberRole;
  status: AssociationMemberStatus;
  /** ISO-8601 */
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export enum AssociationMemberRole {
  Director = 'DIRECTOR',
  Guest = 'GUEST',
  President = 'PRESIDENT',
  PropertyManager = 'PROPERTY_MANAGER',
  RecordingSecretary = 'RECORDING_SECRETARY',
  Secretary = 'SECRETARY',
  Treasurer = 'TREASURER',
  VicePresident = 'VICE_PRESIDENT'
}

export enum AssociationMemberStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type AssociationMemberUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<AssociationMemberRole>;
  status?: InputMaybe<AssociationMemberStatus>;
};

export enum AssociationSensitiveTopic {
  Acquisitions = 'ACQUISITIONS',
  ClientInformation = 'CLIENT_INFORMATION',
  FinancialDetails = 'FINANCIAL_DETAILS',
  LegalMatters = 'LEGAL_MATTERS',
  PersonnelInformation = 'PERSONNEL_INFORMATION',
  SecurityDetails = 'SECURITY_DETAILS',
  StrategicPlans = 'STRATEGIC_PLANS',
  TradeSecrets = 'TRADE_SECRETS'
}

export enum AssociationStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Suspended = 'SUSPENDED'
}

export enum AssociationType {
  Business = 'BUSINESS',
  Civic = 'CIVIC',
  Condo = 'CONDO',
  Coop = 'COOP',
  Council = 'COUNCIL',
  Cultural = 'CULTURAL',
  Educational = 'EDUCATIONAL',
  Hoa = 'HOA',
  Master = 'MASTER',
  Nonprofit = 'NONPROFIT',
  Other = 'OTHER',
  Political = 'POLITICAL',
  Religious = 'RELIGIOUS',
  Social = 'SOCIAL',
  Sports = 'SPORTS',
  Strata = 'STRATA'
}

export type AssociationUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  compositionDateFormat?: InputMaybe<Scalars['String']['input']>;
  compositionDetectParticipantLateArrival?: InputMaybe<Scalars['Boolean']['input']>;
  compositionIncludeActionItems?: InputMaybe<Scalars['Boolean']['input']>;
  compositionIncludeRestrictedItems?: InputMaybe<Scalars['Boolean']['input']>;
  compositionInstructions?: InputMaybe<Scalars['String']['input']>;
  compositionNumbersDecimalPlaces?: InputMaybe<Scalars['Int']['input']>;
  compositionOnlyIncludeDecimalsIfNonzero?: InputMaybe<Scalars['Boolean']['input']>;
  compositionTimeFormat?: InputMaybe<Scalars['String']['input']>;
  compositionWordingOverrides?: InputMaybe<Array<InputMaybe<Entry_String_StringInput>>>;
  country?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  defaultMeetingFrequency?: InputMaybe<MeetingFrequency>;
  defaultMeetingLocationType?: InputMaybe<MeetingLocationType>;
  defaultMeetingProvider?: InputMaybe<MeetingProvider>;
  defaultMeetingTimezone?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  sensitiveTopics?: InputMaybe<Array<InputMaybe<AssociationSensitiveTopic>>>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AssociationStatus>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AssociationType>;
  website?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type Composition = {
  __typename?: 'Composition';
  associationId: Scalars['String']['output'];
  baseTemplateId: Scalars['String']['output'];
  /** ISO-8601 */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  meetingId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  outputPath?: Maybe<Scalars['String']['output']>;
  status: CompositionStatus;
  templatePath?: Maybe<Scalars['String']['output']>;
  transcriptionPath?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export enum CompositionStatus {
  Archived = 'ARCHIVED',
  Composing = 'COMPOSING',
  CompositionFailed = 'COMPOSITION_FAILED',
  CompositionReady = 'COMPOSITION_READY',
  Published = 'PUBLISHED',
  Reviewing = 'REVIEWING',
  Transcribing = 'TRANSCRIBING',
  TranscriptionCompleted = 'TRANSCRIPTION_COMPLETED',
  TranscriptionFailed = 'TRANSCRIPTION_FAILED'
}

export type CompositionsRequestFilterInput = {
  after?: InputMaybe<Scalars['BigInteger']['input']>;
  limit?: InputMaybe<Scalars['BigInteger']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CompositionStatus>;
  templateId?: InputMaybe<Scalars['String']['input']>;
};

export type Configuration = {
  __typename?: 'Configuration';
  firebase: FirebaseConfiguration;
  googleAuth: GoogleAuthConfiguration;
  stripe: StripeConfiguration;
};

export type Document = {
  __typename?: 'Document';
  associationId: Scalars['String']['output'];
  compositionId?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  meetingId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  storage: Scalars['String']['output'];
  storagePath: Scalars['String']['output'];
  type: DocumentType;
  /** ISO-8601 */
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type DocumentInput = {
  /** ISO-8601 */
  createdAt: Scalars['DateTime']['input'];
  createdBy?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 */
  updatedAt: Scalars['DateTime']['input'];
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentRequestFilterInput = {
  after?: InputMaybe<Scalars['BigInteger']['input']>;
  compositionId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['BigInteger']['input']>;
  meetingId?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<DocumentType>;
};

export enum DocumentType {
  Minutes = 'MINUTES',
  Other = 'OTHER'
}

export type Entry_String_String = {
  __typename?: 'Entry_String_String';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Entry_String_StringInput = {
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type FirebaseConfiguration = {
  __typename?: 'FirebaseConfiguration';
  apiKey: Scalars['String']['output'];
  appId: Scalars['String']['output'];
  authDomain: Scalars['String']['output'];
  measurementId: Scalars['String']['output'];
  messagingSenderId: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  storageBucket: Scalars['String']['output'];
};

export type GoogleAuthConfiguration = {
  __typename?: 'GoogleAuthConfiguration';
  scopes: Array<Maybe<Scalars['String']['output']>>;
};

export type GptFile = {
  __typename?: 'GptFile';
  file_id: Scalars['String']['output'];
  filename?: Maybe<Scalars['String']['output']>;
};

export type GptFileCitation = {
  __typename?: 'GptFileCitation';
  end_index: Scalars['Int']['output'];
  file_citation: GptFile;
  start_index: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

export type GptFileInput = {
  file_id: Scalars['String']['input'];
  filename?: InputMaybe<Scalars['String']['input']>;
};

export type GptFilePath = {
  __typename?: 'GptFilePath';
  end_index: Scalars['Int']['output'];
  file_path: GptFile;
  start_index: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

export type GptMessage = {
  __typename?: 'GptMessage';
  attachments?: Maybe<Array<Maybe<GptFile>>>;
  content: Array<Maybe<GptMessageContent>>;
  /** ISO-8601 */
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  role: GptMessageRole;
};

export type GptMessageContent = {
  __typename?: 'GptMessageContent';
  text: GptMessageContentText;
  type: Scalars['String']['output'];
};

export type GptMessageContentAnnotation = GptFileCitation | GptFilePath;

export type GptMessageContentText = {
  __typename?: 'GptMessageContentText';
  annotations: Array<Maybe<GptMessageContentAnnotation>>;
  value: Scalars['String']['output'];
};

export enum GptMessageRole {
  Assistant = 'assistant',
  User = 'user'
}

export type GptMessages = {
  __typename?: 'GptMessages';
  data: Array<Maybe<GptMessage>>;
  first_id?: Maybe<Scalars['String']['output']>;
  has_more: Scalars['Boolean']['output'];
  last_id?: Maybe<Scalars['String']['output']>;
};

export type Meeting = {
  __typename?: 'Meeting';
  agenda?: Maybe<Scalars['String']['output']>;
  associationId: Scalars['String']['output'];
  chatThreadId?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  endTime?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  location: Scalars['String']['output'];
  locationType: MeetingLocationType;
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  participants: Array<MeetingParticipant>;
  recordingUrl?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  startTime: Scalars['DateTime']['output'];
  status: MeetingStatus;
  summaryUrl?: Maybe<Scalars['String']['output']>;
  transcriptFormat?: Maybe<MeetingTranscriptFormat>;
  transcriptUrl?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export enum MeetingFrequency {
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type MeetingInput = {
  /** ISO-8601 */
  createdAt: Scalars['DateTime']['input'];
  createdBy?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 */
  updatedAt: Scalars['DateTime']['input'];
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export enum MeetingLocationType {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL'
}

export type MeetingParticipant = {
  __typename?: 'MeetingParticipant';
  associationId: Scalars['String']['output'];
  associationMemberId: Scalars['String']['output'];
  /** ISO-8601 */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  meetingId: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  participant: AssociationMember;
  status: MeetingParticipantStatus;
  /** ISO-8601 */
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type MeetingParticipantInput = {
  /** ISO-8601 */
  createdAt: Scalars['DateTime']['input'];
  createdBy?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 */
  updatedAt: Scalars['DateTime']['input'];
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export enum MeetingParticipantStatus {
  Attending = 'ATTENDING',
  Declined = 'DECLINED',
  Invited = 'INVITED',
  NoShow = 'NO_SHOW'
}

export enum MeetingProvider {
  GoogleMeet = 'GOOGLE_MEET',
  MicrosoftTeams = 'MICROSOFT_TEAMS',
  Zoom = 'ZOOM'
}

export enum MeetingStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Ended = 'ENDED',
  FailedToJoin_400 = 'FAILED_TO_JOIN_400',
  FailedToJoin_401 = 'FAILED_TO_JOIN_401',
  FailedToJoin_408 = 'FAILED_TO_JOIN_408',
  FailedToJoin_500 = 'FAILED_TO_JOIN_500',
  InProgress = 'IN_PROGRESS',
  Scheduled = 'SCHEDULED',
  SummarizationFailed = 'SUMMARIZATION_FAILED',
  Summarized = 'SUMMARIZED',
  Summarizing = 'SUMMARIZING',
  Transcribing = 'TRANSCRIBING',
  TranscriptionFailed = 'TRANSCRIPTION_FAILED'
}

export enum MeetingTranscriptFormat {
  Json = 'JSON',
  Vtt = 'VTT'
}

export type MeetingsRequestFilterInput = {
  after?: InputMaybe<Scalars['BigInteger']['input']>;
  /** ISO-8601 */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['BigInteger']['input']>;
  locationType?: InputMaybe<MeetingLocationType>;
  query?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 */
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<MeetingStatus>;
};

/** Mutation root */
export type Mutation = {
  __typename?: 'Mutation';
  acceptOrganizationInvite: Scalars['Boolean']['output'];
  addAssociationMember: AssociationMember;
  addMeetingParticipants: Scalars['Boolean']['output'];
  compose: Composition;
  createAssociation: Association;
  createDocuments: Array<Document>;
  createMeeting: Meeting;
  createOrganization: Organization;
  createThread: Array<Maybe<GptMessage>>;
  deactivateAssociationMember: Scalars['Boolean']['output'];
  declineOrganizationInvite: Scalars['Boolean']['output'];
  editMember: OrganizationMember;
  editOrganization: Organization;
  exchangeFirebaseToken: Account;
  grantAssociationAccess: Scalars['Boolean']['output'];
  inviteMember: Scalars['Boolean']['output'];
  joinMeeting: Scalars['Boolean']['output'];
  linkDocumentToComposition: Scalars['Boolean']['output'];
  linkDocumentToMeeting: Scalars['Boolean']['output'];
  removeMeetingParticipants: Scalars['Boolean']['output'];
  revokeAssociationAccess: Scalars['Boolean']['output'];
  sendMessage: GptMessage;
  unlinkDocumentFromComposition: Scalars['Boolean']['output'];
  unlinkDocumentFromMeeting: Scalars['Boolean']['output'];
  updateAssociation: Association;
  updateAssociationMember: AssociationMember;
  updateMeeting: Meeting;
  updateTemplate: Template;
};


/** Mutation root */
export type MutationAcceptOrganizationInviteArgs = {
  account?: InputMaybe<NewAccountInput>;
  token: Scalars['String']['input'];
};


/** Mutation root */
export type MutationAddAssociationMemberArgs = {
  newMember: NewAssociationMemberInput;
};


/** Mutation root */
export type MutationAddMeetingParticipantsArgs = {
  id: Scalars['String']['input'];
  participants: Array<Scalars['String']['input']>;
};


/** Mutation root */
export type MutationComposeArgs = {
  new: NewCompositionInput;
};


/** Mutation root */
export type MutationCreateAssociationArgs = {
  newAssociation: NewAssociationInput;
};


/** Mutation root */
export type MutationCreateDocumentsArgs = {
  documents: Array<NewDocumentInput>;
};


/** Mutation root */
export type MutationCreateMeetingArgs = {
  meeting: NewMeetingInput;
};


/** Mutation root */
export type MutationCreateOrganizationArgs = {
  organization: NewOrganizationInput;
};


/** Mutation root */
export type MutationCreateThreadArgs = {
  newThread: NewThreadInput;
};


/** Mutation root */
export type MutationDeactivateAssociationMemberArgs = {
  memberId: Scalars['String']['input'];
};


/** Mutation root */
export type MutationDeclineOrganizationInviteArgs = {
  token: Scalars['String']['input'];
};


/** Mutation root */
export type MutationEditMemberArgs = {
  member: OrganizationMemberUpdateInput;
};


/** Mutation root */
export type MutationEditOrganizationArgs = {
  organization: OrganizationUpdateInput;
};


/** Mutation root */
export type MutationExchangeFirebaseTokenArgs = {
  token: Scalars['String']['input'];
};


/** Mutation root */
export type MutationGrantAssociationAccessArgs = {
  associationId: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
};


/** Mutation root */
export type MutationInviteMemberArgs = {
  member: NewOrganizationMemberInput;
};


/** Mutation root */
export type MutationJoinMeetingArgs = {
  id: Scalars['String']['input'];
};


/** Mutation root */
export type MutationLinkDocumentToCompositionArgs = {
  compositionId: Scalars['String']['input'];
  documentId: Scalars['String']['input'];
  meetingId: Scalars['String']['input'];
};


/** Mutation root */
export type MutationLinkDocumentToMeetingArgs = {
  documentId: Scalars['String']['input'];
  meetingId: Scalars['String']['input'];
};


/** Mutation root */
export type MutationRemoveMeetingParticipantsArgs = {
  id: Scalars['String']['input'];
  participants: Array<Scalars['String']['input']>;
};


/** Mutation root */
export type MutationRevokeAssociationAccessArgs = {
  associationId: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
};


/** Mutation root */
export type MutationSendMessageArgs = {
  meetingId: Scalars['String']['input'];
  message: Scalars['String']['input'];
};


/** Mutation root */
export type MutationUnlinkDocumentFromCompositionArgs = {
  compositionId: Scalars['String']['input'];
  documentId: Scalars['String']['input'];
  meetingId: Scalars['String']['input'];
};


/** Mutation root */
export type MutationUnlinkDocumentFromMeetingArgs = {
  documentId: Scalars['String']['input'];
  meetingId: Scalars['String']['input'];
};


/** Mutation root */
export type MutationUpdateAssociationArgs = {
  association: AssociationUpdateInput;
};


/** Mutation root */
export type MutationUpdateAssociationMemberArgs = {
  member: AssociationMemberUpdateInput;
};


/** Mutation root */
export type MutationUpdateMeetingArgs = {
  id: Scalars['String']['input'];
  meeting: UpdateMeetingInput;
};


/** Mutation root */
export type MutationUpdateTemplateArgs = {
  id: Scalars['String']['input'];
  update: TemplateUpdateInput;
};

export type NewAccountInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  identifier: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type NewAssociationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  compositionInstructions?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type: AssociationType;
  website?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type NewAssociationMemberInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  role: AssociationMemberRole;
};

export type NewCompositionInput = {
  baseTemplateId: Scalars['String']['input'];
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meetingId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  transcriptionPath?: InputMaybe<Scalars['String']['input']>;
};

export type NewDocumentInput = {
  compositionId?: InputMaybe<Scalars['String']['input']>;
  meetingId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  storagePath: Scalars['String']['input'];
  type?: InputMaybe<DocumentType>;
};

export type NewMeetingInput = {
  agenda?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  location: Scalars['String']['input'];
  locationType: MeetingLocationType;
  name: Scalars['String']['input'];
  participants: Array<InputMaybe<Scalars['String']['input']>>;
  recordingPath?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 */
  startTime: Scalars['DateTime']['input'];
  status?: InputMaybe<MeetingStatus>;
  transcriptFormat?: InputMaybe<MeetingTranscriptFormat>;
  transcriptPath?: InputMaybe<Scalars['String']['input']>;
};

export type NewOrganizationInput = {
  name: Scalars['String']['input'];
};

export type NewOrganizationMemberInput = {
  associationIds: Array<InputMaybe<Scalars['String']['input']>>;
  identifier: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  role: OrganizationMemberRole;
  status?: InputMaybe<OrganizationMemberStatus>;
};

export type NewThreadInput = {
  meetingId: Scalars['String']['input'];
  message: Scalars['String']['input'];
};

export type Organization = {
  __typename?: 'Organization';
  billingCustomerId: Scalars['String']['output'];
  billingSubscriptionId?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  plan?: Maybe<Plan>;
  /** ISO-8601 */
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OrganizationInput = {
  /** ISO-8601 */
  createdAt: Scalars['DateTime']['input'];
  createdBy?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 */
  updatedAt: Scalars['DateTime']['input'];
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationMember = {
  __typename?: 'OrganizationMember';
  avatar?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  id: OrganizationMemberId;
  name?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  role: OrganizationMemberRole;
  status: OrganizationMemberStatus;
  /** ISO-8601 */
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OrganizationMemberId = {
  __typename?: 'OrganizationMemberId';
  identifier: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
};

export type OrganizationMemberInput = {
  /** ISO-8601 */
  createdAt: Scalars['DateTime']['input'];
  createdBy?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 */
  updatedAt: Scalars['DateTime']['input'];
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export enum OrganizationMemberRole {
  Admin = 'ADMIN',
  Owner = 'OWNER',
  User = 'USER'
}

export enum OrganizationMemberStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Declined = 'DECLINED',
  Invited = 'INVITED'
}

export type OrganizationMemberUpdateInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  identifier: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
  role?: InputMaybe<OrganizationMemberRole>;
  status?: InputMaybe<OrganizationMemberStatus>;
};

export type OrganizationMembersFilterInput = {
  after?: InputMaybe<Scalars['BigInteger']['input']>;
  limit?: InputMaybe<Scalars['BigInteger']['input']>;
  orgId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<OrganizationMemberRole>;
  status?: InputMaybe<OrganizationMemberStatus>;
};

export type OrganizationUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Page_Composition = {
  __typename?: 'Page_Composition';
  data: Array<Maybe<Composition>>;
  next?: Maybe<Scalars['Int']['output']>;
};

export type Page_Document = {
  __typename?: 'Page_Document';
  data: Array<Maybe<Document>>;
  next?: Maybe<Scalars['Int']['output']>;
};

export type Page_Meeting = {
  __typename?: 'Page_Meeting';
  data: Array<Maybe<Meeting>>;
  next?: Maybe<Scalars['Int']['output']>;
};

export type Page_OrganizationMember = {
  __typename?: 'Page_OrganizationMember';
  data: Array<Maybe<OrganizationMember>>;
  next?: Maybe<Scalars['Int']['output']>;
};

export type Page_Template = {
  __typename?: 'Page_Template';
  data: Array<Maybe<Template>>;
  next?: Maybe<Scalars['Int']['output']>;
};

export type Plan = {
  __typename?: 'Plan';
  associations: Scalars['BigInteger']['output'];
  compositions: Scalars['BigInteger']['output'];
  id: Scalars['String']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  seats: Scalars['BigInteger']['output'];
  templates: Scalars['BigInteger']['output'];
  upsellPriceId?: Maybe<Scalars['String']['output']>;
};

export type PlanUsage = {
  __typename?: 'PlanUsage';
  associations: Scalars['BigInteger']['output'];
  compositions: Scalars['BigInteger']['output'];
  /** ISO-8601 */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  seats: Scalars['BigInteger']['output'];
  templates: Scalars['BigInteger']['output'];
  /** ISO-8601 */
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
  usageMonth: Scalars['Int']['output'];
  usageYear: Scalars['Int']['output'];
};

/** Query root */
export type Query = {
  __typename?: 'Query';
  association: Association;
  associationMembers: Array<AssociationMember>;
  associationStatuses: Array<AssociationStatus>;
  associationTypes: Array<AssociationType>;
  associations: Array<Association>;
  billingSession: Scalars['String']['output'];
  compositionStatuses: Array<Scalars['String']['output']>;
  compositions: Page_Composition;
  configuration: Configuration;
  documents: Page_Document;
  filename?: Maybe<Scalars['String']['output']>;
  gmailOrGsuite: Scalars['Boolean']['output'];
  handleChatResponse: GptMessage;
  invitation: OrganizationMember;
  meeting: Meeting;
  meetings: Page_Meeting;
  memberById: OrganizationMember;
  memberships: Array<OrganizationMember>;
  messages: GptMessages;
  organization: Organization;
  organizationMemberAssociations: Array<Maybe<Association>>;
  organizationMemberRoles: Array<OrganizationMemberRole>;
  organizationMembers: Page_OrganizationMember;
  participant: AssociationMember;
  participants: Array<MeetingParticipant>;
  plan?: Maybe<Plan>;
  planUsage: PlanUsage;
  recordingUrl?: Maybe<Scalars['String']['output']>;
  self: Account;
  summaryUrl?: Maybe<Scalars['String']['output']>;
  template: Template;
  templates: Page_Template;
  transcriptUrl?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


/** Query root */
export type QueryAssociationArgs = {
  id: Scalars['String']['input'];
};


/** Query root */
export type QueryBillingSessionArgs = {
  returnUrl?: InputMaybe<Scalars['String']['input']>;
};


/** Query root */
export type QueryCompositionsArgs = {
  filter: CompositionsRequestFilterInput;
};


/** Query root */
export type QueryDocumentsArgs = {
  filter: DocumentRequestFilterInput;
};


/** Query root */
export type QueryFilenameArgs = {
  file: GptFileInput;
};


/** Query root */
export type QueryGmailOrGsuiteArgs = {
  email: Scalars['String']['input'];
};


/** Query root */
export type QueryHandleChatResponseArgs = {
  meetingId: Scalars['String']['input'];
};


/** Query root */
export type QueryInvitationArgs = {
  token: Scalars['String']['input'];
};


/** Query root */
export type QueryMeetingArgs = {
  id: Scalars['String']['input'];
};


/** Query root */
export type QueryMeetingsArgs = {
  filter: MeetingsRequestFilterInput;
};


/** Query root */
export type QueryMemberByIdArgs = {
  identifier: Scalars['String']['input'];
};


/** Query root */
export type QueryMessagesArgs = {
  filter: ThreadMessagesRequestFilterInput;
};


/** Query root */
export type QueryOrganizationArgs = {
  member: OrganizationMemberInput;
};


/** Query root */
export type QueryOrganizationMemberAssociationsArgs = {
  orgMemberId: Scalars['String']['input'];
};


/** Query root */
export type QueryOrganizationMembersArgs = {
  filter: OrganizationMembersFilterInput;
};


/** Query root */
export type QueryParticipantArgs = {
  participant: MeetingParticipantInput;
};


/** Query root */
export type QueryParticipantsArgs = {
  meeting: MeetingInput;
};


/** Query root */
export type QueryPlanArgs = {
  organization: OrganizationInput;
};


/** Query root */
export type QueryRecordingUrlArgs = {
  meeting: MeetingInput;
};


/** Query root */
export type QuerySummaryUrlArgs = {
  meeting: MeetingInput;
};


/** Query root */
export type QueryTemplateArgs = {
  id: Scalars['String']['input'];
};


/** Query root */
export type QueryTemplatesArgs = {
  filter: TemplatesRequestFilterInput;
};


/** Query root */
export type QueryTranscriptUrlArgs = {
  meeting: MeetingInput;
};


/** Query root */
export type QueryUrlArgs = {
  document: DocumentInput;
};

export type StripeConfiguration = {
  __typename?: 'StripeConfiguration';
  publicKey: Scalars['String']['output'];
};

/** Subscription root */
export type Subscription = {
  __typename?: 'Subscription';
  composition: Composition;
};


/** Subscription root */
export type SubscriptionCompositionArgs = {
  id: Scalars['String']['input'];
};

export type Template = {
  __typename?: 'Template';
  associationId?: Maybe<Scalars['String']['output']>;
  content: Scalars['String']['output'];
  /** ISO-8601 */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  /** ISO-8601 */
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type TemplateUpdateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TemplatesRequestFilterInput = {
  after?: InputMaybe<Scalars['BigInteger']['input']>;
  associationId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['BigInteger']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ThreadMessagesRequestFilterInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  meetingId: Scalars['String']['input'];
};

export type UpdateMeetingInput = {
  agenda?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  locationType?: InputMaybe<MeetingLocationType>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 */
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<MeetingStatus>;
};

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<_RefType extends Record<string, unknown>> = ResolversObject<{
  GptMessageContentAnnotation: ( GptFileCitation ) | ( GptFilePath );
}>;


/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Account: ResolverTypeWrapper<Account>;
  Association: ResolverTypeWrapper<Association>;
  AssociationMember: ResolverTypeWrapper<AssociationMember>;
  AssociationMemberRole: AssociationMemberRole;
  AssociationMemberStatus: AssociationMemberStatus;
  AssociationMemberUpdateInput: AssociationMemberUpdateInput;
  AssociationSensitiveTopic: AssociationSensitiveTopic;
  AssociationStatus: AssociationStatus;
  AssociationType: AssociationType;
  AssociationUpdateInput: AssociationUpdateInput;
  BigDecimal: ResolverTypeWrapper<Scalars['BigDecimal']['output']>;
  BigInteger: ResolverTypeWrapper<Scalars['BigInteger']['output']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Composition: ResolverTypeWrapper<Composition>;
  CompositionStatus: CompositionStatus;
  CompositionsRequestFilterInput: CompositionsRequestFilterInput;
  Configuration: ResolverTypeWrapper<Configuration>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  Document: ResolverTypeWrapper<Document>;
  DocumentInput: DocumentInput;
  DocumentRequestFilterInput: DocumentRequestFilterInput;
  DocumentType: DocumentType;
  Entry_String_String: ResolverTypeWrapper<Entry_String_String>;
  Entry_String_StringInput: Entry_String_StringInput;
  FirebaseConfiguration: ResolverTypeWrapper<FirebaseConfiguration>;
  GoogleAuthConfiguration: ResolverTypeWrapper<GoogleAuthConfiguration>;
  GptFile: ResolverTypeWrapper<GptFile>;
  GptFileCitation: ResolverTypeWrapper<GptFileCitation>;
  GptFileInput: GptFileInput;
  GptFilePath: ResolverTypeWrapper<GptFilePath>;
  GptMessage: ResolverTypeWrapper<GptMessage>;
  GptMessageContent: ResolverTypeWrapper<GptMessageContent>;
  GptMessageContentAnnotation: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['GptMessageContentAnnotation']>;
  GptMessageContentText: ResolverTypeWrapper<Omit<GptMessageContentText, 'annotations'> & { annotations: Array<Maybe<ResolversTypes['GptMessageContentAnnotation']>> }>;
  GptMessageRole: GptMessageRole;
  GptMessages: ResolverTypeWrapper<GptMessages>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Meeting: ResolverTypeWrapper<Meeting>;
  MeetingFrequency: MeetingFrequency;
  MeetingInput: MeetingInput;
  MeetingLocationType: MeetingLocationType;
  MeetingParticipant: ResolverTypeWrapper<MeetingParticipant>;
  MeetingParticipantInput: MeetingParticipantInput;
  MeetingParticipantStatus: MeetingParticipantStatus;
  MeetingProvider: MeetingProvider;
  MeetingStatus: MeetingStatus;
  MeetingTranscriptFormat: MeetingTranscriptFormat;
  MeetingsRequestFilterInput: MeetingsRequestFilterInput;
  Mutation: ResolverTypeWrapper<{}>;
  NewAccountInput: NewAccountInput;
  NewAssociationInput: NewAssociationInput;
  NewAssociationMemberInput: NewAssociationMemberInput;
  NewCompositionInput: NewCompositionInput;
  NewDocumentInput: NewDocumentInput;
  NewMeetingInput: NewMeetingInput;
  NewOrganizationInput: NewOrganizationInput;
  NewOrganizationMemberInput: NewOrganizationMemberInput;
  NewThreadInput: NewThreadInput;
  Organization: ResolverTypeWrapper<Organization>;
  OrganizationInput: OrganizationInput;
  OrganizationMember: ResolverTypeWrapper<OrganizationMember>;
  OrganizationMemberId: ResolverTypeWrapper<OrganizationMemberId>;
  OrganizationMemberInput: OrganizationMemberInput;
  OrganizationMemberRole: OrganizationMemberRole;
  OrganizationMemberStatus: OrganizationMemberStatus;
  OrganizationMemberUpdateInput: OrganizationMemberUpdateInput;
  OrganizationMembersFilterInput: OrganizationMembersFilterInput;
  OrganizationUpdateInput: OrganizationUpdateInput;
  Page_Composition: ResolverTypeWrapper<Page_Composition>;
  Page_Document: ResolverTypeWrapper<Page_Document>;
  Page_Meeting: ResolverTypeWrapper<Page_Meeting>;
  Page_OrganizationMember: ResolverTypeWrapper<Page_OrganizationMember>;
  Page_Template: ResolverTypeWrapper<Page_Template>;
  Plan: ResolverTypeWrapper<Plan>;
  PlanUsage: ResolverTypeWrapper<PlanUsage>;
  Query: ResolverTypeWrapper<{}>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  StripeConfiguration: ResolverTypeWrapper<StripeConfiguration>;
  Subscription: ResolverTypeWrapper<{}>;
  Template: ResolverTypeWrapper<Template>;
  TemplateUpdateInput: TemplateUpdateInput;
  TemplatesRequestFilterInput: TemplatesRequestFilterInput;
  ThreadMessagesRequestFilterInput: ThreadMessagesRequestFilterInput;
  UpdateMeetingInput: UpdateMeetingInput;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Account: Account;
  Association: Association;
  AssociationMember: AssociationMember;
  AssociationMemberUpdateInput: AssociationMemberUpdateInput;
  AssociationUpdateInput: AssociationUpdateInput;
  BigDecimal: Scalars['BigDecimal']['output'];
  BigInteger: Scalars['BigInteger']['output'];
  Boolean: Scalars['Boolean']['output'];
  Composition: Composition;
  CompositionsRequestFilterInput: CompositionsRequestFilterInput;
  Configuration: Configuration;
  DateTime: Scalars['DateTime']['output'];
  Document: Document;
  DocumentInput: DocumentInput;
  DocumentRequestFilterInput: DocumentRequestFilterInput;
  Entry_String_String: Entry_String_String;
  Entry_String_StringInput: Entry_String_StringInput;
  FirebaseConfiguration: FirebaseConfiguration;
  GoogleAuthConfiguration: GoogleAuthConfiguration;
  GptFile: GptFile;
  GptFileCitation: GptFileCitation;
  GptFileInput: GptFileInput;
  GptFilePath: GptFilePath;
  GptMessage: GptMessage;
  GptMessageContent: GptMessageContent;
  GptMessageContentAnnotation: ResolversUnionTypes<ResolversParentTypes>['GptMessageContentAnnotation'];
  GptMessageContentText: Omit<GptMessageContentText, 'annotations'> & { annotations: Array<Maybe<ResolversParentTypes['GptMessageContentAnnotation']>> };
  GptMessages: GptMessages;
  Int: Scalars['Int']['output'];
  Meeting: Meeting;
  MeetingInput: MeetingInput;
  MeetingParticipant: MeetingParticipant;
  MeetingParticipantInput: MeetingParticipantInput;
  MeetingsRequestFilterInput: MeetingsRequestFilterInput;
  Mutation: {};
  NewAccountInput: NewAccountInput;
  NewAssociationInput: NewAssociationInput;
  NewAssociationMemberInput: NewAssociationMemberInput;
  NewCompositionInput: NewCompositionInput;
  NewDocumentInput: NewDocumentInput;
  NewMeetingInput: NewMeetingInput;
  NewOrganizationInput: NewOrganizationInput;
  NewOrganizationMemberInput: NewOrganizationMemberInput;
  NewThreadInput: NewThreadInput;
  Organization: Organization;
  OrganizationInput: OrganizationInput;
  OrganizationMember: OrganizationMember;
  OrganizationMemberId: OrganizationMemberId;
  OrganizationMemberInput: OrganizationMemberInput;
  OrganizationMemberUpdateInput: OrganizationMemberUpdateInput;
  OrganizationMembersFilterInput: OrganizationMembersFilterInput;
  OrganizationUpdateInput: OrganizationUpdateInput;
  Page_Composition: Page_Composition;
  Page_Document: Page_Document;
  Page_Meeting: Page_Meeting;
  Page_OrganizationMember: Page_OrganizationMember;
  Page_Template: Page_Template;
  Plan: Plan;
  PlanUsage: PlanUsage;
  Query: {};
  String: Scalars['String']['output'];
  StripeConfiguration: StripeConfiguration;
  Subscription: {};
  Template: Template;
  TemplateUpdateInput: TemplateUpdateInput;
  TemplatesRequestFilterInput: TemplatesRequestFilterInput;
  ThreadMessagesRequestFilterInput: ThreadMessagesRequestFilterInput;
  UpdateMeetingInput: UpdateMeetingInput;
}>;

export type ConstraintDirectiveArgs = {
  format?: Maybe<Scalars['String']['input']>;
  max?: Maybe<Scalars['BigInteger']['input']>;
  maxFloat?: Maybe<Scalars['BigDecimal']['input']>;
  maxLength?: Maybe<Scalars['Int']['input']>;
  min?: Maybe<Scalars['BigInteger']['input']>;
  minFloat?: Maybe<Scalars['BigDecimal']['input']>;
  minLength?: Maybe<Scalars['Int']['input']>;
  pattern?: Maybe<Scalars['String']['input']>;
};

export type ConstraintDirectiveResolver<Result, Parent, ContextType = any, Args = ConstraintDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type RolesAllowedDirectiveArgs = {
  value?: Maybe<Scalars['String']['input']>;
};

export type RolesAllowedDirectiveResolver<Result, Parent, ContextType = any, Args = RolesAllowedDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['Account'] = ResolversParentTypes['Account']> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  identifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  whitelisted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AssociationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Association'] = ResolversParentTypes['Association']> = ResolversObject<{
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  compositionDateFormat?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  compositionDetectParticipantLateArrival?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  compositionIncludeActionItems?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  compositionIncludeRestrictedItems?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  compositionInstructions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  compositionNumbersDecimalPlaces?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  compositionOnlyIncludeDecimalsIfNonzero?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  compositionTimeFormat?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  compositionWordingOverrides?: Resolver<Array<Maybe<ResolversTypes['Entry_String_String']>>, ParentType, ContextType, Partial<AssociationCompositionWordingOverridesArgs>>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultMeetingFrequency?: Resolver<Maybe<ResolversTypes['MeetingFrequency']>, ParentType, ContextType>;
  defaultMeetingLocationType?: Resolver<ResolversTypes['MeetingLocationType'], ParentType, ContextType>;
  defaultMeetingProvider?: Resolver<Maybe<ResolversTypes['MeetingProvider']>, ParentType, ContextType>;
  defaultMeetingTimezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orgId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sensitiveTopics?: Resolver<Array<Maybe<ResolversTypes['AssociationSensitiveTopic']>>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['AssociationStatus'], ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AssociationType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AssociationMemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssociationMember'] = ResolversParentTypes['AssociationMember']> = ResolversObject<{
  associationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['AssociationMemberRole'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['AssociationMemberStatus'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface BigDecimalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigDecimal'], any> {
  name: 'BigDecimal';
}

export interface BigIntegerScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInteger'], any> {
  name: 'BigInteger';
}

export type CompositionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Composition'] = ResolversParentTypes['Composition']> = ResolversObject<{
  associationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  baseTemplateId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  meetingId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orgId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outputPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CompositionStatus'], ParentType, ContextType>;
  templatePath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transcriptionPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Configuration'] = ResolversParentTypes['Configuration']> = ResolversObject<{
  firebase?: Resolver<ResolversTypes['FirebaseConfiguration'], ParentType, ContextType>;
  googleAuth?: Resolver<ResolversTypes['GoogleAuthConfiguration'], ParentType, ContextType>;
  stripe?: Resolver<ResolversTypes['StripeConfiguration'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Document'] = ResolversParentTypes['Document']> = ResolversObject<{
  associationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  compositionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  meetingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orgId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  storage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  storagePath?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['DocumentType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Entry_String_StringResolvers<ContextType = any, ParentType extends ResolversParentTypes['Entry_String_String'] = ResolversParentTypes['Entry_String_String']> = ResolversObject<{
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FirebaseConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['FirebaseConfiguration'] = ResolversParentTypes['FirebaseConfiguration']> = ResolversObject<{
  apiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  appId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  authDomain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  measurementId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  messagingSenderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  storageBucket?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GoogleAuthConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['GoogleAuthConfiguration'] = ResolversParentTypes['GoogleAuthConfiguration']> = ResolversObject<{
  scopes?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GptFileResolvers<ContextType = any, ParentType extends ResolversParentTypes['GptFile'] = ResolversParentTypes['GptFile']> = ResolversObject<{
  file_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GptFileCitationResolvers<ContextType = any, ParentType extends ResolversParentTypes['GptFileCitation'] = ResolversParentTypes['GptFileCitation']> = ResolversObject<{
  end_index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  file_citation?: Resolver<ResolversTypes['GptFile'], ParentType, ContextType>;
  start_index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GptFilePathResolvers<ContextType = any, ParentType extends ResolversParentTypes['GptFilePath'] = ResolversParentTypes['GptFilePath']> = ResolversObject<{
  end_index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  file_path?: Resolver<ResolversTypes['GptFile'], ParentType, ContextType>;
  start_index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GptMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['GptMessage'] = ResolversParentTypes['GptMessage']> = ResolversObject<{
  attachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['GptFile']>>>, ParentType, ContextType>;
  content?: Resolver<Array<Maybe<ResolversTypes['GptMessageContent']>>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['GptMessageRole'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GptMessageContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['GptMessageContent'] = ResolversParentTypes['GptMessageContent']> = ResolversObject<{
  text?: Resolver<ResolversTypes['GptMessageContentText'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GptMessageContentAnnotationResolvers<ContextType = any, ParentType extends ResolversParentTypes['GptMessageContentAnnotation'] = ResolversParentTypes['GptMessageContentAnnotation']> = ResolversObject<{
  __resolveType: TypeResolveFn<'GptFileCitation' | 'GptFilePath', ParentType, ContextType>;
}>;

export type GptMessageContentTextResolvers<ContextType = any, ParentType extends ResolversParentTypes['GptMessageContentText'] = ResolversParentTypes['GptMessageContentText']> = ResolversObject<{
  annotations?: Resolver<Array<Maybe<ResolversTypes['GptMessageContentAnnotation']>>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GptMessagesResolvers<ContextType = any, ParentType extends ResolversParentTypes['GptMessages'] = ResolversParentTypes['GptMessages']> = ResolversObject<{
  data?: Resolver<Array<Maybe<ResolversTypes['GptMessage']>>, ParentType, ContextType>;
  first_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  has_more?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  last_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MeetingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Meeting'] = ResolversParentTypes['Meeting']> = ResolversObject<{
  agenda?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  associationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  chatThreadId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locationType?: Resolver<ResolversTypes['MeetingLocationType'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orgId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  participants?: Resolver<Array<ResolversTypes['MeetingParticipant']>, ParentType, ContextType>;
  recordingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MeetingStatus'], ParentType, ContextType>;
  summaryUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transcriptFormat?: Resolver<Maybe<ResolversTypes['MeetingTranscriptFormat']>, ParentType, ContextType>;
  transcriptUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MeetingParticipantResolvers<ContextType = any, ParentType extends ResolversParentTypes['MeetingParticipant'] = ResolversParentTypes['MeetingParticipant']> = ResolversObject<{
  associationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  associationMemberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  meetingId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orgId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  participant?: Resolver<ResolversTypes['AssociationMember'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['MeetingParticipantStatus'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = ResolversObject<{
  acceptOrganizationInvite?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAcceptOrganizationInviteArgs, 'token'>>;
  addAssociationMember?: Resolver<ResolversTypes['AssociationMember'], ParentType, ContextType, RequireFields<MutationAddAssociationMemberArgs, 'newMember'>>;
  addMeetingParticipants?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAddMeetingParticipantsArgs, 'id' | 'participants'>>;
  compose?: Resolver<ResolversTypes['Composition'], ParentType, ContextType, RequireFields<MutationComposeArgs, 'new'>>;
  createAssociation?: Resolver<ResolversTypes['Association'], ParentType, ContextType, RequireFields<MutationCreateAssociationArgs, 'newAssociation'>>;
  createDocuments?: Resolver<Array<ResolversTypes['Document']>, ParentType, ContextType, RequireFields<MutationCreateDocumentsArgs, 'documents'>>;
  createMeeting?: Resolver<ResolversTypes['Meeting'], ParentType, ContextType, RequireFields<MutationCreateMeetingArgs, 'meeting'>>;
  createOrganization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType, RequireFields<MutationCreateOrganizationArgs, 'organization'>>;
  createThread?: Resolver<Array<Maybe<ResolversTypes['GptMessage']>>, ParentType, ContextType, RequireFields<MutationCreateThreadArgs, 'newThread'>>;
  deactivateAssociationMember?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeactivateAssociationMemberArgs, 'memberId'>>;
  declineOrganizationInvite?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeclineOrganizationInviteArgs, 'token'>>;
  editMember?: Resolver<ResolversTypes['OrganizationMember'], ParentType, ContextType, RequireFields<MutationEditMemberArgs, 'member'>>;
  editOrganization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType, RequireFields<MutationEditOrganizationArgs, 'organization'>>;
  exchangeFirebaseToken?: Resolver<ResolversTypes['Account'], ParentType, ContextType, RequireFields<MutationExchangeFirebaseTokenArgs, 'token'>>;
  grantAssociationAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationGrantAssociationAccessArgs, 'associationId' | 'memberId'>>;
  inviteMember?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationInviteMemberArgs, 'member'>>;
  joinMeeting?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationJoinMeetingArgs, 'id'>>;
  linkDocumentToComposition?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationLinkDocumentToCompositionArgs, 'compositionId' | 'documentId' | 'meetingId'>>;
  linkDocumentToMeeting?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationLinkDocumentToMeetingArgs, 'documentId' | 'meetingId'>>;
  removeMeetingParticipants?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveMeetingParticipantsArgs, 'id' | 'participants'>>;
  revokeAssociationAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRevokeAssociationAccessArgs, 'associationId' | 'memberId'>>;
  sendMessage?: Resolver<ResolversTypes['GptMessage'], ParentType, ContextType, RequireFields<MutationSendMessageArgs, 'meetingId' | 'message'>>;
  unlinkDocumentFromComposition?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUnlinkDocumentFromCompositionArgs, 'compositionId' | 'documentId' | 'meetingId'>>;
  unlinkDocumentFromMeeting?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUnlinkDocumentFromMeetingArgs, 'documentId' | 'meetingId'>>;
  updateAssociation?: Resolver<ResolversTypes['Association'], ParentType, ContextType, RequireFields<MutationUpdateAssociationArgs, 'association'>>;
  updateAssociationMember?: Resolver<ResolversTypes['AssociationMember'], ParentType, ContextType, RequireFields<MutationUpdateAssociationMemberArgs, 'member'>>;
  updateMeeting?: Resolver<ResolversTypes['Meeting'], ParentType, ContextType, RequireFields<MutationUpdateMeetingArgs, 'id' | 'meeting'>>;
  updateTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationUpdateTemplateArgs, 'id' | 'update'>>;
}>;

export type OrganizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Organization'] = ResolversParentTypes['Organization']> = ResolversObject<{
  billingCustomerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  billingSubscriptionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plan?: Resolver<Maybe<ResolversTypes['Plan']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganizationMemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganizationMember'] = ResolversParentTypes['OrganizationMember']> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['OrganizationMemberId'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['OrganizationMemberRole'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['OrganizationMemberStatus'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganizationMemberIdResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganizationMemberId'] = ResolversParentTypes['OrganizationMemberId']> = ResolversObject<{
  identifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orgId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Page_CompositionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Page_Composition'] = ResolversParentTypes['Page_Composition']> = ResolversObject<{
  data?: Resolver<Array<Maybe<ResolversTypes['Composition']>>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Page_DocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Page_Document'] = ResolversParentTypes['Page_Document']> = ResolversObject<{
  data?: Resolver<Array<Maybe<ResolversTypes['Document']>>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Page_MeetingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Page_Meeting'] = ResolversParentTypes['Page_Meeting']> = ResolversObject<{
  data?: Resolver<Array<Maybe<ResolversTypes['Meeting']>>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Page_OrganizationMemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['Page_OrganizationMember'] = ResolversParentTypes['Page_OrganizationMember']> = ResolversObject<{
  data?: Resolver<Array<Maybe<ResolversTypes['OrganizationMember']>>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Page_TemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Page_Template'] = ResolversParentTypes['Page_Template']> = ResolversObject<{
  data?: Resolver<Array<Maybe<ResolversTypes['Template']>>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plan'] = ResolversParentTypes['Plan']> = ResolversObject<{
  associations?: Resolver<ResolversTypes['BigInteger'], ParentType, ContextType>;
  compositions?: Resolver<ResolversTypes['BigInteger'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seats?: Resolver<ResolversTypes['BigInteger'], ParentType, ContextType>;
  templates?: Resolver<ResolversTypes['BigInteger'], ParentType, ContextType>;
  upsellPriceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlanUsageResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanUsage'] = ResolversParentTypes['PlanUsage']> = ResolversObject<{
  associations?: Resolver<ResolversTypes['BigInteger'], ParentType, ContextType>;
  compositions?: Resolver<ResolversTypes['BigInteger'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seats?: Resolver<ResolversTypes['BigInteger'], ParentType, ContextType>;
  templates?: Resolver<ResolversTypes['BigInteger'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usageMonth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  usageYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  association?: Resolver<ResolversTypes['Association'], ParentType, ContextType, RequireFields<QueryAssociationArgs, 'id'>>;
  associationMembers?: Resolver<Array<ResolversTypes['AssociationMember']>, ParentType, ContextType>;
  associationStatuses?: Resolver<Array<ResolversTypes['AssociationStatus']>, ParentType, ContextType>;
  associationTypes?: Resolver<Array<ResolversTypes['AssociationType']>, ParentType, ContextType>;
  associations?: Resolver<Array<ResolversTypes['Association']>, ParentType, ContextType>;
  billingSession?: Resolver<ResolversTypes['String'], ParentType, ContextType, Partial<QueryBillingSessionArgs>>;
  compositionStatuses?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  compositions?: Resolver<ResolversTypes['Page_Composition'], ParentType, ContextType, RequireFields<QueryCompositionsArgs, 'filter'>>;
  configuration?: Resolver<ResolversTypes['Configuration'], ParentType, ContextType>;
  documents?: Resolver<ResolversTypes['Page_Document'], ParentType, ContextType, RequireFields<QueryDocumentsArgs, 'filter'>>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryFilenameArgs, 'file'>>;
  gmailOrGsuite?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryGmailOrGsuiteArgs, 'email'>>;
  handleChatResponse?: Resolver<ResolversTypes['GptMessage'], ParentType, ContextType, RequireFields<QueryHandleChatResponseArgs, 'meetingId'>>;
  invitation?: Resolver<ResolversTypes['OrganizationMember'], ParentType, ContextType, RequireFields<QueryInvitationArgs, 'token'>>;
  meeting?: Resolver<ResolversTypes['Meeting'], ParentType, ContextType, RequireFields<QueryMeetingArgs, 'id'>>;
  meetings?: Resolver<ResolversTypes['Page_Meeting'], ParentType, ContextType, RequireFields<QueryMeetingsArgs, 'filter'>>;
  memberById?: Resolver<ResolversTypes['OrganizationMember'], ParentType, ContextType, RequireFields<QueryMemberByIdArgs, 'identifier'>>;
  memberships?: Resolver<Array<ResolversTypes['OrganizationMember']>, ParentType, ContextType>;
  messages?: Resolver<ResolversTypes['GptMessages'], ParentType, ContextType, RequireFields<QueryMessagesArgs, 'filter'>>;
  organization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType, RequireFields<QueryOrganizationArgs, 'member'>>;
  organizationMemberAssociations?: Resolver<Array<Maybe<ResolversTypes['Association']>>, ParentType, ContextType, RequireFields<QueryOrganizationMemberAssociationsArgs, 'orgMemberId'>>;
  organizationMemberRoles?: Resolver<Array<ResolversTypes['OrganizationMemberRole']>, ParentType, ContextType>;
  organizationMembers?: Resolver<ResolversTypes['Page_OrganizationMember'], ParentType, ContextType, RequireFields<QueryOrganizationMembersArgs, 'filter'>>;
  participant?: Resolver<ResolversTypes['AssociationMember'], ParentType, ContextType, RequireFields<QueryParticipantArgs, 'participant'>>;
  participants?: Resolver<Array<ResolversTypes['MeetingParticipant']>, ParentType, ContextType, RequireFields<QueryParticipantsArgs, 'meeting'>>;
  plan?: Resolver<Maybe<ResolversTypes['Plan']>, ParentType, ContextType, RequireFields<QueryPlanArgs, 'organization'>>;
  planUsage?: Resolver<ResolversTypes['PlanUsage'], ParentType, ContextType>;
  recordingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryRecordingUrlArgs, 'meeting'>>;
  self?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  summaryUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QuerySummaryUrlArgs, 'meeting'>>;
  template?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<QueryTemplateArgs, 'id'>>;
  templates?: Resolver<ResolversTypes['Page_Template'], ParentType, ContextType, RequireFields<QueryTemplatesArgs, 'filter'>>;
  transcriptUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryTranscriptUrlArgs, 'meeting'>>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryUrlArgs, 'document'>>;
}>;

export type StripeConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeConfiguration'] = ResolversParentTypes['StripeConfiguration']> = ResolversObject<{
  publicKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = ResolversObject<{
  composition?: SubscriptionResolver<ResolversTypes['Composition'], "composition", ParentType, ContextType, RequireFields<SubscriptionCompositionArgs, 'id'>>;
}>;

export type TemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Template'] = ResolversParentTypes['Template']> = ResolversObject<{
  associationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orgId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  Account?: AccountResolvers<ContextType>;
  Association?: AssociationResolvers<ContextType>;
  AssociationMember?: AssociationMemberResolvers<ContextType>;
  BigDecimal?: GraphQLScalarType;
  BigInteger?: GraphQLScalarType;
  Composition?: CompositionResolvers<ContextType>;
  Configuration?: ConfigurationResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Document?: DocumentResolvers<ContextType>;
  Entry_String_String?: Entry_String_StringResolvers<ContextType>;
  FirebaseConfiguration?: FirebaseConfigurationResolvers<ContextType>;
  GoogleAuthConfiguration?: GoogleAuthConfigurationResolvers<ContextType>;
  GptFile?: GptFileResolvers<ContextType>;
  GptFileCitation?: GptFileCitationResolvers<ContextType>;
  GptFilePath?: GptFilePathResolvers<ContextType>;
  GptMessage?: GptMessageResolvers<ContextType>;
  GptMessageContent?: GptMessageContentResolvers<ContextType>;
  GptMessageContentAnnotation?: GptMessageContentAnnotationResolvers<ContextType>;
  GptMessageContentText?: GptMessageContentTextResolvers<ContextType>;
  GptMessages?: GptMessagesResolvers<ContextType>;
  Meeting?: MeetingResolvers<ContextType>;
  MeetingParticipant?: MeetingParticipantResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Organization?: OrganizationResolvers<ContextType>;
  OrganizationMember?: OrganizationMemberResolvers<ContextType>;
  OrganizationMemberId?: OrganizationMemberIdResolvers<ContextType>;
  Page_Composition?: Page_CompositionResolvers<ContextType>;
  Page_Document?: Page_DocumentResolvers<ContextType>;
  Page_Meeting?: Page_MeetingResolvers<ContextType>;
  Page_OrganizationMember?: Page_OrganizationMemberResolvers<ContextType>;
  Page_Template?: Page_TemplateResolvers<ContextType>;
  Plan?: PlanResolvers<ContextType>;
  PlanUsage?: PlanUsageResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  StripeConfiguration?: StripeConfigurationResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  Template?: TemplateResolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = any> = ResolversObject<{
  constraint?: ConstraintDirectiveResolver<any, any, ContextType>;
  rolesAllowed?: RolesAllowedDirectiveResolver<any, any, ContextType>;
}>;
