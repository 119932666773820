import React from 'react'
import { Association } from '../../types/graphql-types'
import Spacer from '../atoms/spacer'
import { Col, Row, Table } from 'antd'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useNavigate } from 'react-router-dom'
import Button from '../atoms/button'
import { noop } from 'lodash'
import { useAssociations } from '../../hooks/association/useAssociation'

const OrganizationAssociations: React.FC = () => {
  useDocumentTitle('Associations')
  const push = useNavigate()
  const { loading, associations } = useAssociations()

  return (
    <>
      <Row align="middle" justify="end">
        <Col>
          <Button
            analyticsId="create_association"
            label="+ create"
            onClick={noop}
            disabled
            tooltip="Please contact us!"
          />
        </Col>
      </Row>

      <Spacer size="large" />
      <Table
        loading={loading}
        rowKey={item => (item as Association).id}
        columns={[
          { title: 'Name', dataIndex: 'name' },
          { title: 'Status', dataIndex: 'status' },
          { title: 'Type', dataIndex: 'type' },
        ]}
        dataSource={associations}
        onRow={(record: any) => ({
          style: { cursor: 'pointer' },
          onClick: () => push(`/settings?ass=${record.id}`),
        })}
        pagination={false}
      />
    </>
  )
}

export default OrganizationAssociations
