import { gql } from '@apollo/client'
import useQuery from '../useQuery'
import { OrganizationMember } from '../../types/graphql-types'
import { noop } from 'lodash'

const query = gql`
  query Memberships {
    memberships {
      id {
        identifier
        orgId
      }
      role
      status
      name
      organization {
        id
        name
        logo
        description
        plan {
          id
          name
          level
          seats
          associations
          compositions
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`

const useMemberships = () => {
  const { loading, data, refetch } = useQuery<{
    memberships: OrganizationMember[]
  }>(query)

  return {
    loading,
    memberships: data?.memberships ?? [],
    refetch: () => refetch().then(noop),
  }
}

export default useMemberships
