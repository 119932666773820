import styled from 'styled-components'
import React, { HTMLAttributes, PropsWithChildren } from 'react'
import XhrPending from '../atoms/xhr-pending'
import { Row } from 'antd'

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  height: 100%;
  overflow: auto;
`

type PageProps = PropsWithChildren &
  HTMLAttributes<HTMLDivElement> & {
    xhrPending?: boolean
  }
export const Page: React.FC<PageProps> = ({
  children,
  xhrPending,
  ...props
}) => (
  <XhrPending pending={xhrPending ?? false}>
    <PageContainer {...props}>{children}</PageContainer>
  </XhrPending>
)

export const PageHeader = styled(Row)`
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  flex-grow: 0;
`

export const PageContent = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 16px;
`
