import React, { PropsWithChildren, useEffect, useState } from 'react'
import useConfiguration from '../hooks/useConfiguration'
import { FirebaseApp, initializeApp } from 'firebase/app'
import { Analytics, getAnalytics } from 'firebase/analytics'
import { GoogleAuthProvider } from 'firebase/auth'
import LoadingPage from '../components/pages/loading'

type Google = {
  auth: GoogleAuthProvider
}

type AppContextProps = {
  firebase?: FirebaseApp
  analytics?: Analytics
  google?: Google
}

export const AppContext = React.createContext<AppContextProps>({})

export const AppContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    loading,
    firebase: firebaseConfig,
    googleAuth: googleConfig,
  } = useConfiguration()
  const [firebase, setFirebase] = useState<FirebaseApp>()
  const [analytics, setAnalytics] = useState<Analytics>()
  const [google, setGoogle] = useState<Google>()

  useEffect(() => {
    if (firebaseConfig) setFirebase(initializeApp(firebaseConfig, 'myrna'))
  }, [firebaseConfig])

  useEffect(() => {
    if (firebase && !analytics) {
      setAnalytics(getAnalytics(firebase))
    }
    // eslint-disable-next-line
  }, [firebase])

  useEffect(() => {
    if (googleConfig) {
      const auth = new GoogleAuthProvider()
      googleConfig.scopes
        ?.filter(Boolean)
        .forEach(scope => auth.addScope(scope!!))
      setGoogle({ auth })
    }
  }, [googleConfig])

  return loading || !firebase || !analytics || !google ? (
    <LoadingPage />
  ) : (
    <AppContext.Provider value={{ firebase, analytics, google }}>
      {children}
    </AppContext.Provider>
  )
}
